import { SelectOption, SelectValue } from '@mui/base';
import type { SelectProps as BaseSelectProps } from '@mui/base/Select';
import { Select as BaseSelect } from '@mui/base/Select';
import React, { useCallback } from 'react';

import { cn } from '../../lib/utils';

interface SelectProps<
  OptionValue extends NonNullable<unknown>,
  Multiple extends boolean
> extends BaseSelectProps<OptionValue, Multiple> {
  onValueChange?: (value: SelectValue<OptionValue, Multiple>) => void;
  multiline?: boolean;
}

// Adapted from mui-base/src/Select/Select.tsx
const defaultRenderValue = <OptionValue,>(
  selectedOptions:
    | SelectOption<OptionValue>
    | SelectOption<OptionValue>[]
    | null
) => {
  if (Array.isArray(selectedOptions)) {
    if (selectedOptions.length == 0) {
      return null; // Fallback to placeholder
    }
    return (
      <React.Fragment>
        {selectedOptions.map(o => o.label).join(', ')}
      </React.Fragment>
    );
  }

  return selectedOptions?.label ?? null;
};

export const Select = <
  OptionValue extends NonNullable<unknown>,
  Multiple extends boolean
>(
  props: SelectProps<OptionValue, Multiple>
) => {
  const {
    onValueChange,
    value,
    multiline = false,
    renderValue = defaultRenderValue,
    multiple,
    ...others
  } = props;
  const handleValueChange: BaseSelectProps<OptionValue, Multiple>['onChange'] =
    useCallback(
      (
        _: React.MouseEvent | React.KeyboardEvent | React.FocusEvent | null,
        value: SelectValue<OptionValue, Multiple>
      ) => {
        onValueChange?.(value);
      },
      [onValueChange]
    );

  const selected = Boolean(
    multiple && Array.isArray(value) ? value.length > 0 : value
  );

  return (
    <BaseSelect
      {...others}
      onChange={onValueChange ? handleValueChange : undefined}
      value={value}
      renderValue={renderValue}
      multiple={multiple}
      slotProps={{
        root: {
          className: cn(
            'w-full border border-select-foreground rounded-lg p-3.5 text-left bg-input-background',
            {
              'font-bold': selected,
              truncate: !multiline
            }
          )
        },
        listbox: {
          className:
            'border border-select-foreground rounded-lg mt-4 bg-white max-h-64 overflow-scroll divide-y divide-solid'
        }
      }}
    />
  );
};

export type { SelectProps };

export type { SelectOption } from '@mui/base/useOption';
export type { SelectValue } from '@mui/base/useSelect';
