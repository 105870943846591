import { MenuItem } from '@mui/base/MenuItem';
import { FC, PropsWithChildren, useCallback } from 'react';
import { NavLink, To } from 'react-router-dom';

import { cn } from '../../lib/utils';

interface NavLinkRenderProps {
  isActive: boolean;
  isPending: boolean;
  isTransitioning: boolean;
}

export interface NavigationMenuItem extends PropsWithChildren {
  to?: To;
  icon?: string;
  active?: boolean;
  className?: string;
  level?: 0 | 1;
}

export const NavigationMenuItem: FC<NavigationMenuItem> = ({
  children,
  to,
  icon,
  level = 0
}) => {
  const defaultClassName = 'flex h-[50px] items-center pl-4';
  const content = (
    <div
      style={icon ? { backgroundImage: `url("${icon}")` } : undefined}
      className={cn(
        'flex',
        {
          'bg-left bg-no-repeat': icon,
          "before:content-['•'] before:block before:mr-4": !icon && level == 1
        },
        'pl-[34px]'
      )}
    >
      {children}
    </div>
  );

  const navLinkClassName = useCallback(
    ({ isActive }: NavLinkRenderProps): string | undefined =>
      cn(defaultClassName, {
        'bg-nav-active rounded-r-3xl font-bold': isActive
      }),
    [defaultClassName]
  );

  return (
    <MenuItem>
      {to ? (
        <NavLink to={to} className={navLinkClassName}>
          {content}
        </NavLink>
      ) : (
        <div className={cn(defaultClassName, 'pointer-events-none')}>
          {content}
        </div>
      )}
    </MenuItem>
  );
};
