import { FC, useMemo } from 'react';

import iconAlert from '../assets/icon-alert.svg';
import soundWaveform from '../assets/sound-waveform.svg';

export interface MessagePlaceholderProps {
  icon?: 'default' | 'alert';
  title?: string;
  text?: string;
}

export const MessagePlaceholder: FC<MessagePlaceholderProps> = ({
  icon = 'default',
  title,
  text
}) => {
  const image = useMemo(() => {
    switch (icon) {
      case 'alert':
        return iconAlert;
      case 'default':
      default:
        return soundWaveform;
    }
  }, [icon]);
  return (
    <div className="flex flex-col items-center gap-2">
      <img src={image} className="w-40" />
      <div className="text-center">
        {title && <div className="text-2xl">{title}</div>}
        {text && <div className="max-w-72">{text}</div>}
      </div>
    </div>
  );
};
