import { KeyRequirement } from '../models/KeyRequirement';

const requirementMaps: Record<KeyRequirement, string[]> = {
  'walking-with-purpose': [
    'footstep',
    'thud',
    'door-knock',
    'appliance-beep',
    'fire-smoke-alarm',
    'water-running'
  ],
  'fall-prevention': [
    'footstep',
    'thud',
    'door-knock',
    'appliance-beep',
    'fire-smoke-alarm',
    'scream', // TODO
    'cough'
  ],
  nursing: ['cough', 'scream', 'gasp', 'footstep', 'thud', 'appliance-beep'],
  infection: ['cough', 'gasp', 'movement'],
  'complex-needs': []
};

const automaticallyIdentifyKeyRequirement = (
  soundSlugs: string[]
): KeyRequirement | undefined => {
  const candidates = Object.entries(requirementMaps).map(
    ([requirement, requirementSlugs]) => {
      return [
        requirement,
        soundSlugs.filter(slug => requirementSlugs.includes(slug)).length
      ] as [string, number];
    }
  );
  candidates.sort((a, b) => b[1] - a[1]);
  return candidates[0][0] as KeyRequirement | undefined;
};

const specialMonitorIdKeyRequirementMapping: Record<string, KeyRequirement> = {
  '976': 'walking-with-purpose',
  '977': 'walking-with-purpose',
  '889': 'complex-needs',
  '874': 'complex-needs'
};

export const temporaryIdentifyKeyRequirement = (
  monitorId: string,
  soundSlugs: string[]
): KeyRequirement | undefined => {
  if (import.meta.env.MODE === 'production') {
    const requirement = specialMonitorIdKeyRequirementMapping[monitorId];
    if (requirement) {
      return requirement;
    }
  }

  return automaticallyIdentifyKeyRequirement(soundSlugs);
};
