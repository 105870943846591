import { createListenerMiddleware } from '@reduxjs/toolkit';

import { backendApi } from '../api/backendApi';
import { AppDispatch, RootState } from '.';
import { login, logout } from './auth';

export const resetCacheMiddleware = createListenerMiddleware();

const startAppListening = resetCacheMiddleware.startListening.withTypes<
  RootState,
  AppDispatch
>();

startAppListening({
  actionCreator: login,
  effect: (action, listenerApi) => {
    const {
      user: { id }
    } = action.payload;

    const {
      auth: { user: originalUserState }
    } = listenerApi.getOriginalState();
    if (originalUserState?.id !== id) {
      listenerApi.dispatch(backendApi.util.resetApiState());
    }
  }
});

startAppListening({
  actionCreator: logout,
  effect: (_, listenerApi) => {
    listenerApi.dispatch(backendApi.util.resetApiState());
  }
});
