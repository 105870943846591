import { LoginUser } from '../api/types';

/**
 * Checks that the user has the required plan to access dashboard.
 *
 * This is currently done in the client because the backend does not have
 * facility to do this in the login API. Will fix this in the backend.
 */
export const userHasRequiredPlan = ({ plan }: LoginUser): boolean => {
  return ['carehome', 'carehome_two'].includes(plan);
};
