import { format, Locale } from 'date-fns';
import { useCallback } from 'react';
import type {
  DayPickerDefaultProps,
  DayPickerMultipleProps,
  DayPickerRangeProps,
  DayPickerSingleProps
} from 'react-day-picker';
import { DayPicker, Formatters } from 'react-day-picker';

export type CalendarProps =
  | DayPickerDefaultProps
  | DayPickerSingleProps
  | DayPickerMultipleProps
  | DayPickerRangeProps;

export const Calendar: React.FC<CalendarProps> = props => {
  const formatWeekdayName: Formatters['formatWeekdayName'] = useCallback(
    (weekday: Date, options?: { locale?: Locale }) => {
      return format(weekday, 'ccc', options);
    },
    []
  );
  return (
    <DayPicker
      {...props}
      classNames={{
        head: 'bg-black2 text-xs uppercase text-center text-calendar-weekday',
        row: 'text-base text-center',
        caption: 'flex',
        caption_label: 'grow',
        cell: 'w-8 h-8 p-0',
        day: 'w-full h-full',
        day_today: 'font-bold',
        day_disabled: 'text-calendar-weekday',
        day_range_start:
          "bg-black text-white rounded-full before:content-[' '] relative before:block before:absolute before:top-0 before:left-1/2 before:bottom-0 before:right-0 before:bg-calendar-selectRange before:z-[-1]",
        day_range_end:
          "bg-black text-white rounded-full before:content-[' '] relative before:block before:absolute before:top-0 before:right-1/2 before:bottom-0 before:left-0 before:bg-calendar-selectRange before:z-[-1]",
        day_range_middle: 'bg-calendar-selectRange text-black'
      }}
      formatters={{
        formatWeekdayName
      }}
    />
  );
};
