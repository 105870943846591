import { Button } from '@mui/base/Button';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import eyeClosed from '../../assets/eye-closed.svg';
import eyeOpen from '../../assets/eye-open.svg';
import { Input as BaseInput, InputProps } from './Input';

export type PasswordInputProps = InputProps & {
  multiline?: undefined;
  type?: undefined;
};

const PasswordInput = React.forwardRef<HTMLInputElement, PasswordInputProps>(
  ({ ...props }, ref) => {
    const [reveal, setReveal] = useState(false);
    const type = reveal ? 'text' : 'password';
    const { t } = useTranslation();

    const toggle = useCallback(() => {
      setReveal(orig => !orig);
    }, []);

    return (
      <BaseInput
        ref={ref}
        endAdornment={
          <Button
            aria-label={
              reveal
                ? t('components.ui.PasswordInput.hide')
                : t('components.ui.PasswordInput.reveal')
            }
            onClick={toggle}
            className="m-2 inline-flex items-center"
          >
            <img
              aria-hidden="true"
              src={reveal ? eyeOpen : eyeClosed}
              className="h-[18px] w-[20px]"
            />
          </Button>
        }
        {...props}
        type={type}
      />
    );
  }
);
PasswordInput.displayName = 'PasswordInput';

export { PasswordInput };
