import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Label,
  Line,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';

import { getChartNotice } from '../../components/charts/ChartNotice';
import { useRechartsTooltipFormatter } from '../../hooks/useRechartsTooltipFormatter';
import { polynomial } from '../../utils/recharts/polynomial';

export interface AcrossMonitorsChartProps {
  data: { date: string; events: number }[];
  title: string;
  subtitles: string[];
  yAxisTitle: string;
  barColor?: string;
  chartType: 'anomaly' | 'total';
}

export const AcrossMonitorsChart: FC<AcrossMonitorsChartProps> = ({
  title,
  subtitles,
  data,
  yAxisTitle,
  barColor = '#f9db56',
  chartType
}) => {
  const { t } = useTranslation();
  const width = 1000;
  const height = 800;

  const hasEnoughDataForAnomalies = data.length >= 7;

  const effectiveChartType =
    chartType == 'anomaly' && hasEnoughDataForAnomalies ? chartType : 'total';

  const tooltipFormatter = useRechartsTooltipFormatter();

  const anomalyNoticeLeft = 80;

  return (
    <ComposedChart
      width={width}
      height={height}
      data={data}
      margin={{
        top: 60 + 10 + subtitles.length * 20,
        right: 0,
        left: 20,
        bottom: 80
      }}
    >
      <text
        x={width / 2}
        y={20}
        fill="black"
        textAnchor="middle"
        dominantBaseline="central"
      >
        <tspan fontSize="24" x={width / 2}>
          {title}
        </tspan>
        {subtitles.map((subtitle, i) => {
          return (
            <tspan
              fontSize="16"
              x={width / 2}
              dy={(i == 0 ? 10 : 0) + 20}
              key={i}
            >
              {subtitle}
            </tspan>
          );
        })}
      </text>
      {chartType == 'anomaly' && !hasEnoughDataForAnomalies
        ? getChartNotice({
            x: anomalyNoticeLeft,
            y: 60,
            label: t('components.charts.ChartNotice.text.trends')
          })
        : null}
      <CartesianGrid vertical={false} />
      <XAxis dataKey="date" tickLine={false} angle={-90} textAnchor="end" />
      <YAxis axisLine={false} tickLine={false}>
        <Label
          dx={-40}
          value={yAxisTitle}
          position="center"
          style={{ fill: 'black' }}
          angle={-90}
        />
      </YAxis>
      <Tooltip formatter={tooltipFormatter} />
      <Bar dataKey="events" barSize={40} fill={barColor} />
      {effectiveChartType === 'anomaly' && (
        <Line
          type={polynomial.range([60, 690])}
          dataKey="events"
          stroke="#f2b13d"
          isAnimationActive={false}
          dot={false}
          tooltipType="none"
          strokeWidth={2}
        />
      )}
    </ComposedChart>
  );
};
