import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

interface User {
  id: number;
  email: string;
  full_name: string;
}

export interface AuthState {
  token: string | null;
  isAuthenticated: boolean;
  user: User | null;
}

interface LoginPayload {
  token: string;
  user: User;
}

const initialState: AuthState = {
  token: null,
  isAuthenticated: false,
  user: null
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action: PayloadAction<LoginPayload>) => {
      const { token, user } = action.payload;
      return {
        ...state,
        token,
        isAuthenticated: true,
        user
      };
    },
    logout: state => {
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        user: null
      };
    }
  }
});

export const { login, logout } = authSlice.actions;

export default authSlice.reducer;
