// file: emptySplitApi.ts noEmit
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import queryString from 'query-string';

import { RootState } from '../store';

export const emptySplitApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_API_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;
      const { token } = state.auth;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
        // TEMP: Route request to another backend target group
        headers.set('x-earzz-api-client', 'dashboard');
      }
      return headers;
    },
    paramsSerializer: params =>
      queryString.stringify(params, { arrayFormat: 'none' })
  }),
  endpoints: () => ({})
});
