import { ChangeEventHandler, useCallback, useMemo, useState } from 'react';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  XAxis,
  YAxis
} from 'recharts';

import { useColorPreference } from '../hooks/useColorPreference';

const AllSounds = [
  {
    id: 3,
    active: true,
    slug: 'appliance-beep',
    name: 'Appliance Beep'
  },
  {
    id: 4,
    active: true,
    slug: 'child-sound',
    name: 'Child Sound'
  },
  {
    id: 5,
    active: true,
    slug: 'baby-cry',
    name: 'Baby Cry'
  },
  {
    id: 6,
    active: false,
    slug: 'chime',
    name: 'Door Chime'
  },
  {
    id: 7,
    active: false,
    slug: 'bike',
    name: 'Motorbike Engine'
  },
  {
    id: 9,
    active: false,
    slug: 'car-engine',
    name: 'Car Engine'
  },
  {
    id: 10,
    active: false,
    slug: 'car-alarm',
    name: 'Car Alarm'
  },
  {
    id: 11,
    active: false,
    slug: 'cat-flap',
    name: 'Door Flap'
  },
  {
    id: 12,
    active: true,
    slug: 'cat-meow',
    name: 'Cat Meow'
  },
  {
    id: 14,
    active: true,
    slug: 'cough',
    name: 'Cough'
  },
  {
    id: 15,
    active: false,
    slug: 'cycle-lock',
    name: 'Cycle Lock Break'
  },
  {
    id: 16,
    active: true,
    slug: 'dog-bark',
    name: 'Dog Bark'
  },
  {
    id: 17,
    active: false,
    slug: 'dog-whimper',
    name: 'Dog Whimper'
  },
  {
    id: 18,
    active: true,
    slug: 'door-bell',
    name: 'Door Bell'
  },
  {
    id: 19,
    active: true,
    slug: 'buzzer',
    name: 'Buzzer'
  },
  {
    id: 20,
    active: true,
    slug: 'door-knock',
    name: 'Knock'
  },
  {
    id: 21,
    active: false,
    slug: 'activity',
    name: 'Activity'
  },
  {
    id: 23,
    active: true,
    slug: 'fire-bell',
    name: 'Alarm Bell'
  },
  {
    id: 24,
    active: true,
    slug: 'fire-smoke-alarm',
    name: 'Alarm Beep'
  },
  {
    id: 25,
    active: false,
    slug: 'garage',
    name: 'Garage Open/Close'
  },
  {
    id: 26,
    active: false,
    slug: 'baby-gate',
    name: 'Baby Gate'
  },
  {
    id: 27,
    active: false,
    slug: 'glass',
    name: 'Glass Shatter'
  },
  {
    id: 28,
    active: false,
    slug: 'gravel',
    name: 'Gravel Crunching'
  },
  {
    id: 29,
    active: false,
    slug: 'gun',
    name: 'Gunshot'
  },
  {
    id: 30,
    active: true,
    slug: 'alarm-siren',
    name: 'Siren'
  },
  {
    id: 31,
    active: false,
    slug: 'icecream-truck',
    name: 'Ice Cream Truck'
  },
  {
    id: 32,
    active: true,
    slug: 'kettle-whistle',
    name: 'Kettle Whistle'
  },
  {
    id: 33,
    active: false,
    slug: 'water-boiling',
    name: 'Boiling Water'
  },
  {
    id: 34,
    active: false,
    slug: 'kitchen-timer',
    name: 'Kitchen Timer'
  },
  {
    id: 35,
    active: false,
    slug: 'mailbox-slot',
    name: 'Letter Flap/Mail Slot'
  },
  {
    id: 36,
    active: false,
    slug: 'motorbike-alarm',
    name: 'Motorbike Alarm'
  },
  {
    id: 37,
    active: true,
    slug: 'snore',
    name: 'Snoring'
  },
  {
    id: 38,
    active: true,
    slug: 'telephone-digital',
    name: 'Digital Telephone'
  },
  {
    id: 39,
    active: false,
    slug: 'vaccum',
    name: 'Vacuum Cleaner'
  },
  {
    id: 40,
    active: true,
    slug: 'water-running',
    name: 'Water Running'
  },
  {
    id: 41,
    active: false,
    slug: 'water',
    name: 'Water Dripping'
  },
  {
    id: 42,
    active: true,
    slug: 'glass-break',
    name: 'Glass Break'
  },
  {
    id: 43,
    active: true,
    slug: 'scream',
    name: 'Scream'
  },
  {
    id: 45,
    active: true,
    slug: 'clap',
    name: 'Clap'
  },
  {
    id: 46,
    active: true,
    slug: 'thud',
    name: 'Thud'
  },
  {
    id: 47,
    active: false,
    slug: 'gasp',
    name: 'Gasping'
  },
  {
    id: 48,
    active: false,
    slug: 'footstep',
    name: 'Movement Noise'
  },
  {
    id: 481,
    active: false,
    slug: 'footstep-moderate',
    name: 'Movement Noise'
  },
  {
    id: 482,
    active: false,
    slug: 'footstep-strict',
    name: 'Movement Noise'
  },
  {
    id: 483,
    active: false,
    slug: 'footstep-stricter',
    name: 'Movement Noise'
  },
  {
    id: 484,
    active: false,
    slug: 'footstep-intermediate',
    name: 'Movement Noise'
  }
];

export const DevelopmentSoundColorScreen = () => {
  const { getSoundColor } = useColorPreference();
  const defaultAllSounds = useMemo(() => {
    return AllSounds.map(sound => {
      return {
        ...sound,
        color: getSoundColor(sound.slug)
      };
    });
  }, [getSoundColor]);
  const [sounds, setSounds] = useState(defaultAllSounds);
  const [enabled, setEnabled] = useState<string[]>([
    'footstep',
    'cough',
    'thud'
  ]);

  const handleChangeColor: ChangeEventHandler<HTMLInputElement> = useCallback(
    e => {
      const slug = e.target.getAttribute('data-sound-slug');
      if (!slug) {
        return;
      }
      setSounds(orig => {
        return orig.map(sound => {
          if (sound.slug == slug) {
            return {
              ...sound,
              color: e.target.value
            };
          }
          return sound;
        });
      });
    },
    []
  );

  const handleCheckbox: ChangeEventHandler<HTMLInputElement> = useCallback(
    e => {
      const slug = e.target.getAttribute('data-sound-slug');
      if (!slug) {
        return;
      }
      setEnabled(orig => {
        if (orig.includes(slug)) {
          return orig.filter(s => s != slug);
        } else {
          return [...orig, slug];
        }
      });
    },
    []
  );

  const handleTextareaChange: ChangeEventHandler<HTMLTextAreaElement> =
    useCallback(e => {
      setSounds(JSON.parse(e.target.value));
    }, []);

  const data = useMemo(() => {
    const result: Record<string, number>[] = [];
    for (let x = 1; x <= 10; x++) {
      result.push({
        x,
        ...AllSounds.map(sound => sound.slug).reduce<Record<string, number>>(
          (prev, slug) => {
            prev[slug] = Math.floor(Math.random() * 100);
            return prev;
          },
          {}
        )
      });
    }
    return result;
  }, []);

  return (
    <main className="flex h-screen p-4">
      <div className="overflow-y-scroll">
        {sounds.map(sound => (
          <div key={sound.slug} className="mb-2 flex gap-2">
            <input
              id={sound.slug}
              type="checkbox"
              checked={enabled.includes(sound.slug)}
              data-sound-slug={sound.slug}
              onChange={handleCheckbox}
            />
            <input
              id={sound.slug}
              type="color"
              value={sound.color}
              onChange={handleChangeColor}
              data-sound-slug={sound.slug}
            />
            <input
              type="text"
              value={sound.color}
              className="w-20 border-2 px-1"
              onChange={handleChangeColor}
              data-sound-slug={sound.slug}
            />
            <label htmlFor={sound.slug}>{sound.slug}</label>
          </div>
        ))}
      </div>
      <div>
        <ComposedChart width={800} height={800} data={data}>
          <CartesianGrid vertical={false} />
          <XAxis dataKey="x" tickLine={false} textAnchor="end" dx={-5} />
          <YAxis axisLine={false} tickLine={false} />
          {enabled.map(slug => (
            <Bar
              key={slug}
              dataKey={slug}
              barSize={40}
              fill={sounds.find(sound => sound.slug == slug)?.color}
            />
          ))}
          <Legend />
        </ComposedChart>
        <div>Copy and paste the content below to save/restore changes:</div>
        <textarea
          className="h-[200px] w-[800px] border-2 p-1"
          value={JSON.stringify(sounds)}
          onChange={handleTextareaChange}
        ></textarea>
      </div>
    </main>
  );
};
