import { FC } from 'react';
import { Trans } from 'react-i18next';

import hero from '../assets/sound-waveform.svg';
import { KeyRequirement } from '../models/KeyRequirement';

interface KeyRequirementWidgetProps {
  requirement: KeyRequirement;
}

export const KeyRequirementWidget: FC<KeyRequirementWidgetProps> = ({
  requirement
}) => {
  return (
    <div className="inline-block">
      <div
        style={{ backgroundImage: `url("${hero}")` }}
        className="flex h-[71px] w-[350px] flex-col bg-[length:97px_71px] bg-no-repeat pl-[84px]"
      >
        <div className="pl-4 text-3xl uppercase">
          <Trans i18nKey={`models.KeyRequirement.${requirement}`}>
            <strong>some</strong>monitors
          </Trans>
        </div>
      </div>
    </div>
  );
};
