import { createListenerMiddleware } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { REHYDRATE } from 'redux-persist';

import { AppDispatch, RootState } from '.';
import { login, logout } from './auth';

export const sentryMiddleware = createListenerMiddleware();

const startAppListening = sentryMiddleware.startListening.withTypes<
  RootState,
  AppDispatch
>();

startAppListening({
  type: REHYDRATE,
  effect: (_action, listenerApi) => {
    const {
      auth: { user }
    } = listenerApi.getState();
    if (user) {
      const { id } = user;
      Sentry.setUser({ id });
    }
  }
});

startAppListening({
  actionCreator: login,
  effect: action => {
    const {
      user: { id }
    } = action.payload;
    Sentry.setUser({ id });
  }
});

startAppListening({
  actionCreator: logout,
  effect: () => {
    Sentry.setUser(null);
  }
});
