import { addDays, formatDate, isSameDay, startOfDay } from 'date-fns';
import { PropsWithChildren, useCallback, useMemo } from 'react';
import { Trans } from 'react-i18next';

import { cn } from '../lib/utils';

const datesFromDateRange = (range: ChartDateSelectorDateRange): Date[] => {
  const result: Date[] = [];
  let date = startOfDay(range.from);
  const endDate = startOfDay(range.to);
  while (date <= endDate) {
    result.push(date);
    date = addDays(date, 1);
  }
  return result;
};

interface ChartDateSelectorDateRange {
  from: Date;
  to: Date;
}

interface ChartDateSelectorItemProps extends PropsWithChildren {
  value: Date;
  onSelect: (value: Date) => void;
  selected?: boolean;
}

export const ChartDateSelectorItem = ({
  value,
  selected = false,
  onSelect
}: ChartDateSelectorItemProps) => {
  const handleSelect = useCallback(() => {
    onSelect(value);
  }, [onSelect, value]);

  const formattedString = useMemo(() => {
    return formatDate(value, 'dd');
  }, [value]);

  return (
    <li
      className={cn('px-1 cursor-pointer underline', {
        'font-bold': selected
      })}
      onClick={handleSelect}
    >
      {formattedString}
    </li>
  );
};

interface ChartDateSelectorProps {
  value: Date | undefined;
  onChange: (value: Date | undefined) => void;
  range: ChartDateSelectorDateRange | undefined;
}

export const ChartDateSelector = (props: ChartDateSelectorProps) => {
  const { value, onChange, range } = props;
  const dates = useMemo(() => {
    return range ? datesFromDateRange(range) : [];
  }, [range]);
  const handleSelect = useCallback(
    (newValue: Date) => {
      if (value && isSameDay(value, newValue)) {
        onChange(undefined);
        return;
      }
      onChange(newValue);
    },
    [onChange, value]
  );

  const canGoPrevious = useMemo(() => {
    return !value || !isSameDay(value, dates[0]);
  }, [dates, value]);

  const canGoNext = useMemo(() => {
    return !value || !isSameDay(value, dates[dates.length - 1]);
  }, [dates, value]);

  const handlePrevious = useCallback(() => {
    if (!value) {
      handleSelect(dates[dates.length - 1]);
      return;
    }
    const index = dates.findIndex(d => isSameDay(d, value));
    if (index > 0) {
      handleSelect(dates[index - 1]);
    }
  }, [dates, handleSelect, value]);

  const handleNext = useCallback(() => {
    if (!value) {
      handleSelect(dates[0]);
      return;
    }
    const index = dates.findIndex(d => isSameDay(d, value));
    if (index < dates.length - 1) {
      handleSelect(dates[index + 1]);
    }
  }, [dates, handleSelect, value]);
  return (
    <div className="flex">
      <div className="pr-1">
        <Trans i18nKey="components.ChartDateSelector.label" />
      </div>
      <ul className="flex">
        {dates.map(d => (
          <ChartDateSelectorItem
            key={d.getDate()}
            value={d}
            onSelect={handleSelect}
            selected={value ? isSameDay(d, value) : false}
          />
        ))}
        <li
          onClick={handlePrevious}
          className={cn('px-1 font-bold', {
            'opacity-50 cursor-default': !canGoPrevious,
            'cursor-pointer': canGoPrevious
          })}
        >
          &lt;
        </li>
        <li
          onClick={handleNext}
          className={cn('px-1 font-bold', {
            'opacity-50 cursor-default': !canGoNext,
            'cursor-pointer': canGoNext
          })}
        >
          &gt;
        </li>
      </ul>
    </div>
  );
};
