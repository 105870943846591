export const calculateBarOpacity = ({
  value,
  average,
  maximum,
  baseOpacity = 0.2
}: {
  value: number;
  average: number;
  maximum: number;
  baseOpacity?: number;
}): number => {
  if (value < average) return baseOpacity;

  return ((value - average) / (maximum - average)) * 0.5 + 0.3 + baseOpacity;
};

export const pivot = (
  data: Record<string, string>[],
  key: string,
  dimension: string,
  field: string
): Record<string, string>[] => {
  return data.reduce<Record<string, string>[]>((prev, d) => {
    const {
      [dimension]: dimensionValue,
      [field]: fieldValue,
      [key]: keyValue
    } = d;

    if (prev.length == 0 || prev[prev.length - 1][key] !== keyValue) {
      prev.push({ [key]: keyValue });
    }

    prev[prev.length - 1][dimensionValue] = fieldValue;
    return prev;
  }, []);
};
