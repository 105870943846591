import { FormControl } from '@mui/base/FormControl';
import {
  ChangeEventHandler,
  KeyboardEventHandler,
  MouseEventHandler,
  PropsWithChildren,
  useCallback,
  useMemo
} from 'react';
import type { FieldPath, FieldValues } from 'react-hook-form';
import { ControllerProps, useController } from 'react-hook-form';
import { Trans } from 'react-i18next';

import { cn } from '../../lib/utils';
import { Option } from '../ui/Option';
import { Select, SelectOption, SelectValue } from '../ui/Select';

export type TimeRangeFormControlProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = Omit<ControllerProps<TFieldValues, TName>, 'render'> &
  PropsWithChildren & {
    label?: string | null;
    className?: string;
    placeholder?: string;
    readOnly?: boolean;
  };

export const TimeRangeFormControl = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  props: TimeRangeFormControlProps<TFieldValues, TName>
) => {
  const { label, className, placeholder, children, readOnly, ...others } =
    props;
  const { name } = others;
  const {
    field: { onChange, value, disabled }
  } = useController(others);

  const handleInputClick: MouseEventHandler<HTMLDivElement> = useCallback(e => {
    e.stopPropagation();
  }, []);

  const handleSelectValueChange = useCallback(
    (value: string | null) => {
      if (!value || value === 'custom') {
        return;
      }
      const [from, to] = value.split('-');
      onChange({ from, to });
    },
    [onChange]
  );

  const { from, to } = useMemo(() => {
    return value ?? { from: undefined, to: undefined };
  }, [value]);

  const selectedValue = useMemo(() => {
    if (typeof value !== 'object' || value === null) {
      return '';
    }

    if (!from || !to) {
      return '';
    }

    return `${from}-${to}`;
  }, [from, to, value]);

  const handleFromInputChange: ChangeEventHandler<HTMLInputElement> =
    useCallback(e => onChange({ from: e.target.value, to }), [onChange, to]);

  const handleToInputChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    e => onChange({ from, to: e.target.value }),
    [onChange, from]
  );

  const renderValue = useCallback(
    (option: SelectValue<SelectOption<string>, false>) => {
      if (from && to) {
        return `${from}-${to}`;
      }

      if (option && option.value !== 'custom') {
        return option.label;
      }

      return placeholder;
    },
    [placeholder, from, to]
  );

  // NOTE: The Select element captures key down event to change focus
  // to the option starting with the character of the key. This stops
  // the Select from switching focus.
  const handleInputKeyDown: KeyboardEventHandler<HTMLInputElement> =
    useCallback(e => e.stopPropagation(), []);

  return (
    <FormControl
      className={cn(className, { 'opacity-50': disabled || readOnly })}
    >
      <label htmlFor={name}>{label}</label>
      <Select
        onValueChange={handleSelectValueChange}
        value={selectedValue}
        multiple={false}
        renderValue={renderValue}
        disabled={disabled || readOnly}
      >
        {children}
        <Option value="custom" className="p-4">
          <Trans i18nKey="components.forms.TimeRangeFormControl.custom" />
          <span onClick={handleInputClick}>
            <input
              type="time"
              onChange={handleFromInputChange}
              onKeyDown={handleInputKeyDown}
              step={3600}
              value={from ?? '00:00'}
            />
            &nbsp;-&nbsp;
            <input
              type="time"
              onChange={handleToInputChange}
              onKeyDown={handleInputKeyDown}
              step={3600}
              value={to ?? '00:00'}
            />
          </span>
        </Option>
      </Select>
    </FormControl>
  );
};
