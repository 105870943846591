import { Dropdown } from '@mui/base/Dropdown';
import { Menu } from '@mui/base/Menu';
import { MenuButton } from '@mui/base/MenuButton';
import { MenuItem } from '@mui/base/MenuItem';
import { useCallback, useMemo, useRef } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { cn } from '../lib/utils';
import { RootState } from '../store';
import { logout as logoutAction } from '../store/auth';
import { Button } from './ui/Button';

export const UserMenu = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.auth);
  const handleLogout = useCallback(() => {
    dispatch(logoutAction());
  }, [dispatch]);

  const initials = useMemo(() => {
    return user?.full_name[0] ?? '';
  }, [user?.full_name]);

  const buttonRef = useRef(null);

  return (
    <Dropdown>
      <MenuButton
        ref={buttonRef}
        className="m-2 size-[60px] rounded-full bg-black text-3xl font-bold text-white"
      >
        {initials}
      </MenuButton>
      <Menu
        anchor={buttonRef.current}
        slotProps={{
          root: {
            className: cn(
              'bg-white w-[366px] p-2.5 border-2 border-black border-solid mt-2 -ml-2'
            )
          },
          listbox: {
            className: 'flex flex-col gap-4'
          }
        }}
      >
        {user && (
          <MenuItem className="my-2 text-center text-2xl">
            {user.full_name}
          </MenuItem>
        )}
        <MenuItem>
          <Button className="w-full" onClick={handleLogout}>
            <Trans i18nKey="layouts.Layout.logout" />
          </Button>
        </MenuItem>
      </Menu>
    </Dropdown>
  );
};
