import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Formatter } from 'recharts/types/component/DefaultTooltipContent';

export const useRechartsTooltipFormatter = (): Formatter<number, string> => {
  const intl = useIntl();

  const formatter: Formatter<number, string> = useCallback(
    value => {
      return intl.formatNumber(value, { maximumSignificantDigits: 3 });
    },
    [intl]
  );
  return formatter;
};
