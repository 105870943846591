import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { cn } from '../lib/utils';
import {
  MessagePlaceholder,
  MessagePlaceholderProps
} from './MessagePlaceholder';

export interface CurtainProps extends MessagePlaceholderProps {
  className?: string;
  act?: 'loading' | 'empty' | 'no-selection' | 'todo' | 'error';
}

export const Curtain: FC<CurtainProps> = props => {
  const { t } = useTranslation();
  const { act, className, ...others } = props;
  switch (act) {
    case 'loading':
      others.title = others.title ?? t('components.Curtain.loading.title');
      break;
    case 'no-selection':
      others.text = others.text ?? t('components.Curtain.noSelection.text');
      break;
    case 'empty':
      others.title = others.title ?? t('components.Curtain.empty.title');
      others.text = others.text ?? t('components.Curtain.empty.text');
      break;
    case 'todo':
      others.title = others.title ?? t('components.Curtain.todo.title');
      break;
    case 'error':
      others.icon = 'alert';
      others.text = others.text ?? t('components.Curtain.error.text');
      break;
  }

  return (
    <div
      className={cn('flex size-full items-center justify-center', className)}
    >
      <MessagePlaceholder {...others} />
    </div>
  );
};
