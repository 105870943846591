import * as Sentry from '@sentry/react';
import { CookieHub } from 'react-cookiehub';
import TagManager from 'react-gtm-module';

export const initializeSentry = () => {
  const dsn = import.meta.env.VITE_SENTRY_DSN;
  const environment = import.meta.env.VITE_SENTRY_ENVIRONMENT;
  const release = import.meta.env.VITE_SENTRY_RELEASE;

  if (!dsn) {
    console.warn('Sentry is not initialized because DSN is not configured.');
    return;
  }

  Sentry.init({
    dsn,
    environment,
    release,
    integrations: [Sentry.browserTracingIntegration()],
    debug: import.meta.env.DEV
  });
};

export const destroySentry = () => {
  Sentry.close();
};

// NOTE: This tracker does not set cookie, so do not require cookie consent.
export const initializeGoogleTagManager = () => {
  const gtmId = import.meta.env.VITE_GOOGLE_TAG_MANAGER_ID;
  if (!gtmId) {
    console.info(
      'Google Tag Manager is not initialized because GTM ID is not configured.'
    );
    return;
  }

  TagManager.initialize({ gtmId });
};

export const initializeCookieHub = () => {
  if (
    import.meta.env.DEV &&
    import.meta.env.VITE_DEV_INITIALIZE_COOKIEHUB != 'true'
  ) {
    console.info(
      "CookieHub is not initialized because the app is in development and VITE_DEV_INITIALIZE_COOKIEHUB is not 'true'."
    );
    return;
  }
  const domainCode = import.meta.env.VITE_COOKIEHUB_DOMAIN_CODE;
  if (!domainCode) {
    console.warn(
      'CookieHub is not initialized because domain code is not configured.'
    );
    return;
  }

  CookieHub.initialize(import.meta.env.VITE_COOKIEHUB_DOMAIN_CODE, {
    onAllow: category => {
      if (category === 'analytics') {
        initializeSentry();
      }
    },
    onRevoke: category => {
      if (category === 'analytics') {
        destroySentry();
      }
    },
    debug: import.meta.env.DEV
  });
};
