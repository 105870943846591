import { useMemo } from 'react';

type UseDataAggregateArgs<F extends string, T extends { [x in F]: number }> =
  | {
      data: T[];
      selector: F;
    }
  | {
      data: T[];
      selectors: F[];
    };

interface UseDataAggregateValues {
  average: number | undefined;
  maximum: number | undefined;
  nonZeroCount: number | undefined;
}

export const useDataAggregate = <
  F extends string,
  T extends { [x in F]: number } = { [x in F]: number }
>(
  args: UseDataAggregateArgs<F, T>
): UseDataAggregateValues => {
  const { data } = args;
  const points = useMemo(() => {
    if ('selector' in args) {
      return data.map(e => e[args.selector]);
    }

    return data.map(e =>
      args.selectors.reduce((p, selector) => e[selector] + p, 0)
    );
  }, [args, data]);

  const average = useMemo(() => {
    if (points.length == 0) return undefined;

    const total = points.reduce((prev, count) => prev + count, 0);
    return total / points.length;
  }, [points]);

  const maximum = useMemo(() => {
    if (points.length == 0) return undefined;

    return points.reduce((prev, count) => Math.max(prev, count), 0);
  }, [points]);

  const nonZeroCount = useMemo(() => {
    if (points.length == 0) return undefined;

    return points.filter(value => value > 0).length;
  }, [points]);

  return { average, maximum, nonZeroCount };
};
