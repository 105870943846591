import { FormControl } from '@mui/base/FormControl';
import { parseISO } from 'date-fns';
import { t } from 'i18next';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';
import * as z from 'zod';

import {
  useFetchStatisticsDateRangeOptionsQuery,
  useFetchStatisticsMonitorOptionsQuery,
  useFetchStatisticsRoutineOptionsQuery,
  useFetchStatisticsSoundOptionsQuery
} from '../../api/backendApi';
import { CalendarFormControl } from '../../components/forms/CalendarFormControl';
import { SelectFormControl } from '../../components/forms/SelectFormControl';
import { TimeRangeFormControl } from '../../components/forms/TimeRangeFormControl';
import { Button } from '../../components/ui/Button';
import { Option } from '../../components/ui/Option';
import { TimeRangeOption } from '../../components/ui/TimeRangeOption';
import { useAutoSubmitFormEffect } from '../../hooks/useAutoSubmitFormEffect';
import { isNonNull } from '../../utils/null';

export const MonitorFilterFormInputsSchema = z.object({
  monitor_id: z.string().min(1),
  sound_slugs: z.array(z.string().min(1)).min(1),
  date_range: z.object({ from: z.date(), to: z.date() }),
  time_range: z.object({ from: z.string(), to: z.string() }),
  graph: z.enum(['average', 'count'])
});

export interface MonitorFilterFormInputs {
  monitor_id?: string;
  sound_slugs?: (string | null)[];
  date_range?: { from: Date | null | undefined; to: Date | null | undefined };
  time_range?: {
    from: string | null | undefined;
    to: string | null | undefined;
  };
  graph: 'average' | 'count';
}

export interface MonitorFilterFormProps {
  values?: Partial<MonitorFilterFormInputs> | null;
  onChange: (values: MonitorFilterFormInputs | null) => void;
}

export const MonitorFilterForm: FC<MonitorFilterFormProps> = ({
  values,
  onChange
}) => {
  const { watch, control, handleSubmit, reset } =
    useForm<MonitorFilterFormInputs>();

  const handleReset = useCallback(() => {
    onChange(null);
  }, [onChange]);

  const [filterFormValues, setFilterFormValues] = useState<
    Partial<MonitorFilterFormInputs>
  >({});

  useAutoSubmitFormEffect({ watch, handleSubmit }, onChange);

  useEffect(() => {
    const subscription = watch(formValues => {
      setFilterFormValues(formValues as Partial<MonitorFilterFormInputs>);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    reset(values ?? {});
  }, [reset, values]);

  const {
    data: { monitor_options: monitors } = {},
    isFetching: isMonitorsFetching
  } = useFetchStatisticsMonitorOptionsQuery({});
  const { data: { sound_options: sounds } = {}, isFetching: isSoundFetching } =
    useFetchStatisticsSoundOptionsQuery({
      monitorId: filterFormValues.monitor_id
    });
  const { data: { date_range } = {} } = useFetchStatisticsDateRangeOptionsQuery(
    {
      monitorId: filterFormValues.monitor_id,
      soundSlugs: filterFormValues.sound_slugs?.filter(isNonNull) ?? []
    },
    {
      selectFromResult: ({ data, ...rest }) => {
        if (!data) {
          return { data, ...rest };
        }
        return {
          data: {
            date_range: data.date_range.map(v => (v ? parseISO(v) : undefined))
          },
          ...rest
        };
      }
    }
  );
  const { data: routineData } = useFetchStatisticsRoutineOptionsQuery(
    {
      monitorId: filterFormValues.monitor_id!
    },
    { skip: !filterFormValues.monitor_id }
  );

  const now = useMemo(() => new Date(), []);

  return (
    <form className="flex gap-x-10">
      <SelectFormControl
        className="flex-1"
        name="monitor_id"
        label={t('DataViewMonitor.filter.monitor.label')}
        placeholder={
          isMonitorsFetching
            ? t('loading')
            : t('DataViewMonitor.filter.monitor.placeholder')
        }
        defaultValue=""
        control={control}
        readOnly={isMonitorsFetching}
      >
        {monitors?.map(({ key, value }) => (
          <Option key={key} value={key} className="p-4">
            {value}
          </Option>
        ))}
      </SelectFormControl>
      <SelectFormControl
        className="max-w-48 flex-1"
        name="sound_slugs"
        label={t('DataViewMonitor.filter.sound.label')}
        placeholder={
          isMonitorsFetching
            ? t('loading')
            : t('DataViewMonitor.filter.sound.placeholder')
        }
        defaultValue={[]}
        control={control}
        readOnly={!filterFormValues.monitor_id || isSoundFetching}
        multiple={true}
      >
        {sounds?.map(({ key, value }) => (
          <Option key={key} value={key} className="p-4">
            {value}
          </Option>
        ))}
      </SelectFormControl>
      <CalendarFormControl
        className="flex-1"
        name="date_range"
        control={control}
        fromDate={date_range?.[0]}
        // NOTE: We use today's date here rather than the later bound
        // of the date_range because it is confusing to the user why
        // they cannot choose recent dates.
        toDate={now}
        mode="range"
        placeholder={t('DataViewMonitor.filter.date.placeholder')}
        label={t('DataViewMonitor.filter.date.label')}
        readOnly={!filterFormValues.sound_slugs}
      />
      <TimeRangeFormControl
        className="flex-1"
        name="time_range"
        control={control}
        placeholder={t('DataViewMonitor.filter.time.placeholder')}
        label={t('DataViewMonitor.filter.time.label')}
        defaultValue={{ from: '22:00', to: '06:00' }}
      >
        {routineData?.routine && (
          <TimeRangeOption
            from={routineData?.routine?.start_time ?? '00:00'}
            to={routineData?.routine?.end_time ?? '00:00'}
            className="p-4"
          >
            <Trans i18nKey="components.forms.TimeRangeFormControl.routine" />
          </TimeRangeOption>
        )}
        <TimeRangeOption from="22:00" to="06:00" className="p-4">
          <Trans i18nKey="components.forms.TimeRangeFormControl.core-night-time" />
        </TimeRangeOption>
        <TimeRangeOption from="20:00" to="08:00" className="p-4">
          <Trans i18nKey="components.forms.TimeRangeFormControl.night-time" />
        </TimeRangeOption>
        <TimeRangeOption from="00:00" to="00:00" className="p-4">
          <Trans i18nKey="components.forms.TimeRangeFormControl.24hours" />
        </TimeRangeOption>
      </TimeRangeFormControl>
      <SelectFormControl
        className="flex-1"
        name="graph"
        label={t('DataViewMonitor.filter.graph.label')}
        placeholder={t('DataViewMonitor.filter.graph.placeholder')}
        defaultValue="count"
        control={control}
      >
        <Option value="average" className="p-4">
          <Trans i18nKey="DataViewMonitor.filter.graph.average" />
        </Option>
        <Option value="count" className="p-4">
          <Trans i18nKey="DataViewMonitor.filter.graph.count" />
        </Option>
      </SelectFormControl>
      <FormControl className="flex flex-1">
        <Button onClick={handleReset} className="place-self-end">
          <Trans i18nKey="DataViewMonitor.filter.clearAll" />
        </Button>
      </FormControl>
    </form>
  );
};
