import { DailyChartDataPoint } from './DailyChart';
import { HourlyChartDataPoint } from './HourlyChart';

export const makeHourlyChartDataPoint = (
  data: Record<string, number>,
  extras: Record<'hour', string>
): HourlyChartDataPoint => {
  return { ...data, hour: extras.hour } as unknown as HourlyChartDataPoint;
};

export const makeDailyChartDataPoint = (
  data: Record<string, number>,
  extras: Record<'date_time_date', string>
): DailyChartDataPoint => {
  return {
    ...data,
    date_time_date: extras.date_time_date
  } as unknown as DailyChartDataPoint;
};
