export interface UseTODOOptions {
  hideInProduction?: boolean;
  hideInRelease?: boolean;
}

export const useTODO = ({
  hideInProduction = true,
  hideInRelease = false
}: UseTODOOptions = {}): boolean => {
  return (
    (hideInProduction && import.meta.env.MODE != 'development') ||
    (hideInRelease && import.meta.env.PROD)
  );
};
