import { useEffect } from 'react';
import {
  FieldValues,
  SubmitErrorHandler,
  SubmitHandler,
  UseFormReturn
} from 'react-hook-form';

export const useAutoSubmitFormEffect = <
  TFieldValues extends FieldValues = FieldValues,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TContext = any,
  TTransformedValues extends FieldValues | undefined = undefined
>(
  {
    watch,
    handleSubmit
  }: Pick<
    UseFormReturn<TFieldValues, TContext, TTransformedValues>,
    'watch' | 'handleSubmit'
  >,
  onValid: TTransformedValues extends undefined
    ? SubmitHandler<TFieldValues>
    : TTransformedValues extends FieldValues
      ? SubmitHandler<TTransformedValues>
      : never,
  onInvalid?: SubmitErrorHandler<TFieldValues>
) => {
  useEffect(() => {
    const subscription = watch((_, { type }) => {
      if (type == 'change') {
        handleSubmit(onValid, onInvalid)();
      }
    });
    return () => subscription.unsubscribe();
  }, [handleSubmit, onInvalid, onValid, watch]);
};
