import { FC, PropsWithChildren } from 'react';

export interface ChartLoadingContainerProps extends PropsWithChildren {
  isLoading: boolean;
}

export const ChartLoadingContainer: FC<ChartLoadingContainerProps> = ({
  isLoading,
  children
}) => {
  return (
    <div className="relative">
      {children}
      {isLoading ? (
        <div className="absolute inset-0 flex items-center justify-center bg-chartLoading-background">
          <span>Loading</span>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
