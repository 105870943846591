import { emptySplitApi as api } from '../emptyApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    getV1Health: build.query<GetV1HealthApiResponse, GetV1HealthApiArg>({
      query: () => ({ url: `/v1/health` })
    }),
    postV1AuthRegister: build.mutation<
      PostV1AuthRegisterApiResponse,
      PostV1AuthRegisterApiArg
    >({
      query: queryArg => ({
        url: `/v1/auth/register`,
        method: 'POST',
        body: queryArg.body
      })
    }),
    login: build.mutation<LoginApiResponse, LoginApiArg>({
      query: queryArg => ({
        url: `/v1/auth/login`,
        method: 'POST',
        body: queryArg.body
      })
    }),
    postV1AuthForgetPassword: build.mutation<
      PostV1AuthForgetPasswordApiResponse,
      PostV1AuthForgetPasswordApiArg
    >({
      query: queryArg => ({
        url: `/v1/auth/forget-password`,
        method: 'POST',
        body: queryArg.body
      })
    }),
    getV1AuthResetPassword: build.query<
      GetV1AuthResetPasswordApiResponse,
      GetV1AuthResetPasswordApiArg
    >({
      query: queryArg => ({
        url: `/v1/auth/reset-password`,
        params: { token: queryArg.token }
      })
    }),
    postV1AuthResetPassword: build.mutation<
      PostV1AuthResetPasswordApiResponse,
      PostV1AuthResetPasswordApiArg
    >({
      query: queryArg => ({
        url: `/v1/auth/reset-password`,
        method: 'POST',
        body: queryArg.body,
        params: { token: queryArg.token }
      })
    }),
    getV1AuthVerify: build.query<
      GetV1AuthVerifyApiResponse,
      GetV1AuthVerifyApiArg
    >({
      query: queryArg => ({
        url: `/v1/auth/verify`,
        params: { token: queryArg.token }
      })
    }),
    postV1AuthVerify: build.mutation<
      PostV1AuthVerifyApiResponse,
      PostV1AuthVerifyApiArg
    >({
      query: () => ({ url: `/v1/auth/verify`, method: 'POST' })
    }),
    getV1Sounds: build.query<GetV1SoundsApiResponse, GetV1SoundsApiArg>({
      query: () => ({ url: `/v1/sounds/` })
    }),
    getV1SoundsById: build.query<
      GetV1SoundsByIdApiResponse,
      GetV1SoundsByIdApiArg
    >({
      query: queryArg => ({ url: `/v1/sounds/${queryArg.id}` })
    }),
    fetchUserDevices: build.query<
      FetchUserDevicesApiResponse,
      FetchUserDevicesApiArg
    >({
      query: () => ({ url: `/v1/devices/` })
    }),
    putV1Devices: build.mutation<PutV1DevicesApiResponse, PutV1DevicesApiArg>({
      query: queryArg => ({
        url: `/v1/devices/`,
        method: 'PUT',
        body: queryArg.body
      })
    }),
    getV1DevicesByMonitorId: build.query<
      GetV1DevicesByMonitorIdApiResponse,
      GetV1DevicesByMonitorIdApiArg
    >({
      query: queryArg => ({ url: `/v1/devices/${queryArg.monitorId}` })
    }),
    deleteV1DevicesByUdid: build.mutation<
      DeleteV1DevicesByUdidApiResponse,
      DeleteV1DevicesByUdidApiArg
    >({
      query: queryArg => ({
        url: `/v1/devices/${queryArg.udid}`,
        method: 'DELETE'
      })
    }),
    postV1DevicesRegisterByUdid: build.mutation<
      PostV1DevicesRegisterByUdidApiResponse,
      PostV1DevicesRegisterByUdidApiArg
    >({
      query: queryArg => ({
        url: `/v1/devices/register/${queryArg.udid}`,
        method: 'POST',
        body: queryArg.body
      })
    }),
    patchV1DevicesByDeviceIdAndMonitorIdActivate: build.mutation<
      PatchV1DevicesByDeviceIdAndMonitorIdActivateApiResponse,
      PatchV1DevicesByDeviceIdAndMonitorIdActivateApiArg
    >({
      query: queryArg => ({
        url: `/v1/devices/${queryArg.deviceId}/${queryArg.monitorId}/activate`,
        method: 'PATCH',
        body: queryArg.body
      })
    }),
    fetchMonitors: build.query<FetchMonitorsApiResponse, FetchMonitorsApiArg>({
      query: queryArg => ({
        url: `/v1/monitors/`,
        params: { includes: queryArg.includes }
      })
    }),
    postV1Monitors: build.mutation<
      PostV1MonitorsApiResponse,
      PostV1MonitorsApiArg
    >({
      query: queryArg => ({
        url: `/v1/monitors/`,
        method: 'POST',
        body: queryArg.body
      })
    }),
    patchV1MonitorsById: build.mutation<
      PatchV1MonitorsByIdApiResponse,
      PatchV1MonitorsByIdApiArg
    >({
      query: queryArg => ({
        url: `/v1/monitors/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.body
      })
    }),
    deleteV1MonitorsById: build.mutation<
      DeleteV1MonitorsByIdApiResponse,
      DeleteV1MonitorsByIdApiArg
    >({
      query: queryArg => ({
        url: `/v1/monitors/${queryArg.id}`,
        method: 'DELETE'
      })
    }),
    putV1MonitorsByIdSounds: build.mutation<
      PutV1MonitorsByIdSoundsApiResponse,
      PutV1MonitorsByIdSoundsApiArg
    >({
      query: queryArg => ({
        url: `/v1/monitors/${queryArg.id}/sounds`,
        method: 'PUT',
        body: queryArg.body
      })
    }),
    getV1MonitorsByIdSounds: build.query<
      GetV1MonitorsByIdSoundsApiResponse,
      GetV1MonitorsByIdSoundsApiArg
    >({
      query: queryArg => ({ url: `/v1/monitors/${queryArg.id}/sounds` })
    }),
    fetchMonitorRoutine: build.query<
      FetchMonitorRoutineApiResponse,
      FetchMonitorRoutineApiArg
    >({
      query: queryArg => ({ url: `/v1/monitors/${queryArg.id}/routines` })
    }),
    patchMonitorRoutine: build.mutation<
      PatchMonitorRoutineApiResponse,
      PatchMonitorRoutineApiArg
    >({
      query: queryArg => ({
        url: `/v1/monitors/${queryArg.id}/routines`,
        method: 'PATCH',
        body: queryArg.body
      })
    }),
    putV1MonitorsByIdDevice: build.mutation<
      PutV1MonitorsByIdDeviceApiResponse,
      PutV1MonitorsByIdDeviceApiArg
    >({
      query: queryArg => ({
        url: `/v1/monitors/${queryArg.id}/device`,
        method: 'PUT',
        body: queryArg.body
      })
    }),
    patchV1MonitorsByIdLed: build.mutation<
      PatchV1MonitorsByIdLedApiResponse,
      PatchV1MonitorsByIdLedApiArg
    >({
      query: queryArg => ({
        url: `/v1/monitors/${queryArg.id}/led`,
        method: 'PATCH',
        body: queryArg.body
      })
    }),
    patchV1MonitorsByIdNotificationFrequency: build.mutation<
      PatchV1MonitorsByIdNotificationFrequencyApiResponse,
      PatchV1MonitorsByIdNotificationFrequencyApiArg
    >({
      query: queryArg => ({
        url: `/v1/monitors/${queryArg.id}/notification-frequency`,
        method: 'PATCH',
        body: queryArg.body
      })
    }),
    getV1MonitorsExistsByEarzzUid: build.query<
      GetV1MonitorsExistsByEarzzUidApiResponse,
      GetV1MonitorsExistsByEarzzUidApiArg
    >({
      query: queryArg => ({ url: `/v1/monitors/exists/${queryArg.earzzUid}` })
    }),
    fetchMonitorAvailableFirmwares: build.query<
      FetchMonitorAvailableFirmwaresApiResponse,
      FetchMonitorAvailableFirmwaresApiArg
    >({
      query: queryArg => ({
        url: `/v1/monitors/${queryArg.monitorId}/available-firmwares`,
        params: { track: queryArg.track }
      })
    }),
    fetchLatestMonitorFirmwareUpdate: build.query<
      FetchLatestMonitorFirmwareUpdateApiResponse,
      FetchLatestMonitorFirmwareUpdateApiArg
    >({
      query: queryArg => ({
        url: `/v1/monitors/${queryArg.monitorId}/firmware-updates/latest`
      })
    }),
    updateMonitorFirmware: build.mutation<
      UpdateMonitorFirmwareApiResponse,
      UpdateMonitorFirmwareApiArg
    >({
      query: queryArg => ({
        url: `/v1/monitors/${queryArg.monitorId}/firmware-updates`,
        method: 'POST',
        body: queryArg.body
      })
    }),
    fetchMonitorPhysicalAttendance: build.query<
      FetchMonitorPhysicalAttendanceApiResponse,
      FetchMonitorPhysicalAttendanceApiArg
    >({
      query: queryArg => ({
        url: `/v1/monitors/${queryArg.monitorId}/physical-attendance`
      })
    }),
    patchMonitorPhysicalAttendance: build.mutation<
      PatchMonitorPhysicalAttendanceApiResponse,
      PatchMonitorPhysicalAttendanceApiArg
    >({
      query: queryArg => ({
        url: `/v1/monitors/${queryArg.monitorId}/physical-attendance`,
        method: 'PATCH',
        body: queryArg.body
      })
    }),
    disableMonitorPhysicalAttendance: build.mutation<
      DisableMonitorPhysicalAttendanceApiResponse,
      DisableMonitorPhysicalAttendanceApiArg
    >({
      query: queryArg => ({
        url: `/v1/monitors/${queryArg.monitorId}/physical-attendance`,
        method: 'DELETE'
      })
    }),
    postV1MonitorsByMonitorIdNoteEvents: build.mutation<
      PostV1MonitorsByMonitorIdNoteEventsApiResponse,
      PostV1MonitorsByMonitorIdNoteEventsApiArg
    >({
      query: queryArg => ({
        url: `/v1/monitors/${queryArg.monitorId}/note-events`,
        method: 'POST',
        body: queryArg.body
      })
    }),
    getV1Settings: build.query<GetV1SettingsApiResponse, GetV1SettingsApiArg>({
      query: () => ({ url: `/v1/settings/` })
    }),
    patchV1Settings: build.mutation<
      PatchV1SettingsApiResponse,
      PatchV1SettingsApiArg
    >({
      query: () => ({ url: `/v1/settings/`, method: 'PATCH' })
    }),
    putV1SettingsPassword: build.mutation<
      PutV1SettingsPasswordApiResponse,
      PutV1SettingsPasswordApiArg
    >({
      query: queryArg => ({
        url: `/v1/settings/password`,
        method: 'PUT',
        body: queryArg.body
      })
    }),
    patchV1SettingsDetails: build.mutation<
      PatchV1SettingsDetailsApiResponse,
      PatchV1SettingsDetailsApiArg
    >({
      query: queryArg => ({
        url: `/v1/settings/details`,
        method: 'PATCH',
        body: queryArg.body
      })
    }),
    patchV1SettingsSoundReport: build.mutation<
      PatchV1SettingsSoundReportApiResponse,
      PatchV1SettingsSoundReportApiArg
    >({
      query: queryArg => ({
        url: `/v1/settings/sound-report`,
        method: 'PATCH',
        body: queryArg.body
      })
    }),
    deleteV1SettingsDeactivate: build.mutation<
      DeleteV1SettingsDeactivateApiResponse,
      DeleteV1SettingsDeactivateApiArg
    >({
      query: () => ({ url: `/v1/settings/deactivate`, method: 'DELETE' })
    }),
    getV1Events: build.query<GetV1EventsApiResponse, GetV1EventsApiArg>({
      query: queryArg => ({
        url: `/v1/events/`,
        params: {
          cursor: queryArg.cursor,
          limit: queryArg.limit,
          monitorIds: queryArg.monitorIds,
          includes: queryArg.includes
        }
      })
    }),
    getV1EventsByIdFeedback: build.query<
      GetV1EventsByIdFeedbackApiResponse,
      GetV1EventsByIdFeedbackApiArg
    >({
      query: queryArg => ({ url: `/v1/events/${queryArg.id}/feedback` })
    }),
    postV1EventsByIdFeedback: build.mutation<
      PostV1EventsByIdFeedbackApiResponse,
      PostV1EventsByIdFeedbackApiArg
    >({
      query: queryArg => ({
        url: `/v1/events/${queryArg.id}/feedback`,
        method: 'POST',
        body: queryArg.body
      })
    }),
    fetchSoundEventAttendance: build.query<
      FetchSoundEventAttendanceApiResponse,
      FetchSoundEventAttendanceApiArg
    >({
      query: queryArg => ({ url: `/v1/events/${queryArg.eventId}/attendance` })
    }),
    patchSoundEventAttendance: build.mutation<
      PatchSoundEventAttendanceApiResponse,
      PatchSoundEventAttendanceApiArg
    >({
      query: queryArg => ({
        url: `/v1/events/${queryArg.eventId}/attendance`,
        method: 'PATCH',
        body: queryArg.body
      })
    }),
    postV1DebugSoundsClassify: build.mutation<
      PostV1DebugSoundsClassifyApiResponse,
      PostV1DebugSoundsClassifyApiArg
    >({
      query: queryArg => ({
        url: `/v1/debug/sounds/classify`,
        method: 'POST',
        body: queryArg.body
      })
    }),
    postV1AdminDebugSoundsClassify: build.mutation<
      PostV1AdminDebugSoundsClassifyApiResponse,
      PostV1AdminDebugSoundsClassifyApiArg
    >({
      query: queryArg => ({
        url: `/v1/admin/debug/sounds/classify`,
        method: 'POST',
        body: queryArg.body
      })
    }),
    postV1WebhooksFoxyTransaction: build.mutation<
      PostV1WebhooksFoxyTransactionApiResponse,
      PostV1WebhooksFoxyTransactionApiArg
    >({
      query: queryArg => ({
        url: `/v1/webhooks/foxy/transaction`,
        method: 'POST',
        body: queryArg.body,
        headers: { 'foxy-webhook-event': queryArg['foxy-webhook-event'] }
      })
    }),
    postV1WebhooksFoxyCustomer: build.mutation<
      PostV1WebhooksFoxyCustomerApiResponse,
      PostV1WebhooksFoxyCustomerApiArg
    >({
      query: queryArg => ({
        url: `/v1/webhooks/foxy/customer`,
        method: 'POST',
        body: queryArg.body,
        headers: { 'foxy-webhook-event': queryArg['foxy-webhook-event'] }
      })
    }),
    fetchMonitorEventAttendance: build.query<
      FetchMonitorEventAttendanceApiResponse,
      FetchMonitorEventAttendanceApiArg
    >({
      query: queryArg => ({
        url: `/v1/monitor-events/${queryArg.eventId}/attendance`
      })
    }),
    patchMonitorEventAttendance: build.mutation<
      PatchMonitorEventAttendanceApiResponse,
      PatchMonitorEventAttendanceApiArg
    >({
      query: queryArg => ({
        url: `/v1/monitor-events/${queryArg.eventId}/attendance`,
        method: 'PATCH',
        body: queryArg.body
      })
    }),
    fetchEventLogs: build.query<
      FetchEventLogsApiResponse,
      FetchEventLogsApiArg
    >({
      query: queryArg => ({
        url: `/v1/event-logs`,
        params: {
          cursor: queryArg.cursor,
          limit: queryArg.limit,
          monitorIds: queryArg.monitorIds,
          monitorNicknames: queryArg.monitorNicknames,
          soundIds: queryArg.soundIds,
          soundSlugs: queryArg.soundSlugs,
          types: queryArg.types,
          monitorEventTypes: queryArg.monitorEventTypes,
          includes: queryArg.includes
        }
      })
    }),
    getIftttV1Status: build.query<
      GetIftttV1StatusApiResponse,
      GetIftttV1StatusApiArg
    >({
      query: () => ({ url: `/ifttt/v1/status/` })
    }),
    postIftttV1TestSetup: build.mutation<
      PostIftttV1TestSetupApiResponse,
      PostIftttV1TestSetupApiArg
    >({
      query: () => ({ url: `/ifttt/v1/test/setup`, method: 'POST' })
    }),
    postIftttV1TriggersNewSoundEvent: build.mutation<
      PostIftttV1TriggersNewSoundEventApiResponse,
      PostIftttV1TriggersNewSoundEventApiArg
    >({
      query: queryArg => ({
        url: `/ifttt/v1/triggers/new_sound_event`,
        method: 'POST',
        body: queryArg.body
      })
    }),
    postIftttV1TriggersNewSoundEventFieldsSoundIdOptions: build.mutation<
      PostIftttV1TriggersNewSoundEventFieldsSoundIdOptionsApiResponse,
      PostIftttV1TriggersNewSoundEventFieldsSoundIdOptionsApiArg
    >({
      query: () => ({
        url: `/ifttt/v1/triggers/new_sound_event/fields/sound_id/options`,
        method: 'POST'
      })
    }),
    postIftttV1TriggersNewSoundEventFieldsMonitorIdOptions: build.mutation<
      PostIftttV1TriggersNewSoundEventFieldsMonitorIdOptionsApiResponse,
      PostIftttV1TriggersNewSoundEventFieldsMonitorIdOptionsApiArg
    >({
      query: () => ({
        url: `/ifttt/v1/triggers/new_sound_event/fields/monitor_id/options`,
        method: 'POST'
      })
    }),
    deleteIftttV1TriggersNewSoundEventTriggerIdentityByTriggerIdentity:
      build.mutation<
        DeleteIftttV1TriggersNewSoundEventTriggerIdentityByTriggerIdentityApiResponse,
        DeleteIftttV1TriggersNewSoundEventTriggerIdentityByTriggerIdentityApiArg
      >({
        query: queryArg => ({
          url: `/ifttt/v1/triggers/new_sound_event/trigger_identity/${queryArg.triggerIdentity}`,
          method: 'DELETE'
        })
      }),
    getIftttV1UserInfo: build.query<
      GetIftttV1UserInfoApiResponse,
      GetIftttV1UserInfoApiArg
    >({
      query: () => ({ url: `/ifttt/v1/user/info/` })
    }),
    fetchStatisticsMonitorOptions: build.query<
      FetchStatisticsMonitorOptionsApiResponse,
      FetchStatisticsMonitorOptionsApiArg
    >({
      query: queryArg => ({
        url: `/v1/statistics/options/monitors`,
        params: {
          monitor_id: queryArg.monitorId,
          monitor_ids: queryArg.monitorIds,
          sound_slug: queryArg.soundSlug,
          sound_slugs: queryArg.soundSlugs,
          date_range: queryArg.dateRange,
          includes_extras: queryArg.includesExtras
        }
      })
    }),
    fetchStatisticsSoundOptions: build.query<
      FetchStatisticsSoundOptionsApiResponse,
      FetchStatisticsSoundOptionsApiArg
    >({
      query: queryArg => ({
        url: `/v1/statistics/options/sounds`,
        params: {
          monitor_id: queryArg.monitorId,
          monitor_ids: queryArg.monitorIds,
          sound_slug: queryArg.soundSlug,
          sound_slugs: queryArg.soundSlugs,
          date_range: queryArg.dateRange,
          includes_extras: queryArg.includesExtras
        }
      })
    }),
    fetchStatisticsDateRangeOptions: build.query<
      FetchStatisticsDateRangeOptionsApiResponse,
      FetchStatisticsDateRangeOptionsApiArg
    >({
      query: queryArg => ({
        url: `/v1/statistics/options/date-range`,
        params: {
          monitor_id: queryArg.monitorId,
          monitor_ids: queryArg.monitorIds,
          sound_slug: queryArg.soundSlug,
          sound_slugs: queryArg.soundSlugs,
          date_range: queryArg.dateRange,
          includes_extras: queryArg.includesExtras
        }
      })
    }),
    fetchStatisticsRoutineOptions: build.query<
      FetchStatisticsRoutineOptionsApiResponse,
      FetchStatisticsRoutineOptionsApiArg
    >({
      query: queryArg => ({
        url: `/v1/statistics/options/routines`,
        params: { monitor_id: queryArg.monitorId }
      })
    }),
    fetchStatistics: build.query<
      FetchStatisticsApiResponse,
      FetchStatisticsApiArg
    >({
      query: queryArg => ({
        url: `/v1/statistics/query`,
        method: 'POST',
        body: queryArg.body
      })
    })
  }),
  overrideExisting: false
});
export { injectedRtkApi as backendApi };
export type GetV1HealthApiResponse = unknown;
export type GetV1HealthApiArg = void;
export type PostV1AuthRegisterApiResponse = unknown;
export type PostV1AuthRegisterApiArg = {
  body: {
    /** Email address (unique) */
    email: string;
    /** Password (8-32 chars) */
    password: string;
    /** Full name of the user */
    full_name: string;
    /** User region */
    region?:
      | 'United Kingdom'
      | 'United States'
      | 'Switzerland'
      | 'Rest of World';
  };
};
export type LoginApiResponse = /** status 200 OK */ {
  token: string;
  user: {
    id: number;
    foxy_user_id?: number | null;
    email: string;
    full_name: string;
    phone_number?: string | null;
    role?: string;
    verified?: boolean;
    plan: string;
    region?: string | null;
    associated_user_id?: number | null;
    notification_frequency?: string | null;
    receive_sound_report?: boolean | null;
    notification_action?: 'attendance' | 'feedback';
    hash_type?: string | null;
  };
};
export type LoginApiArg = {
  body: {
    /** Email address (unique) */
    email: string;
    /** Password (8-32 chars) */
    password: string;
    /** Unique Device Identifier (UDID) for Android or iOS devices */
    udid?: string;
    /** The client identifier for the client making the request. */
    client_id?: string;
  };
};
export type PostV1AuthForgetPasswordApiResponse = unknown;
export type PostV1AuthForgetPasswordApiArg = {
  body: {
    /** Email address (unique) */
    email: string;
  };
};
export type GetV1AuthResetPasswordApiResponse = unknown;
export type GetV1AuthResetPasswordApiArg = {
  token: string;
};
export type PostV1AuthResetPasswordApiResponse = unknown;
export type PostV1AuthResetPasswordApiArg = {
  token: string;
  body: {
    /** Password (8-32 chars) */
    password: string;
  };
};
export type GetV1AuthVerifyApiResponse = unknown;
export type GetV1AuthVerifyApiArg = {
  token: string;
};
export type PostV1AuthVerifyApiResponse = unknown;
export type PostV1AuthVerifyApiArg = void;
export type GetV1SoundsApiResponse = unknown;
export type GetV1SoundsApiArg = void;
export type GetV1SoundsByIdApiResponse = unknown;
export type GetV1SoundsByIdApiArg = {
  id: number;
};
export type FetchUserDevicesApiResponse = /** status 200 OK */ Device[];
export type FetchUserDevicesApiArg = void;
export type PutV1DevicesApiResponse = unknown;
export type PutV1DevicesApiArg = {
  body: {
    udid: string;
    app_version?: string;
    fcm_push_token?: string;
    model?: string;
    preferred_name?: string;
    platform?: string;
    local_name?: string;
  };
};
export type GetV1DevicesByMonitorIdApiResponse = unknown;
export type GetV1DevicesByMonitorIdApiArg = {
  monitorId: number;
};
export type DeleteV1DevicesByUdidApiResponse = unknown;
export type DeleteV1DevicesByUdidApiArg = {
  /** Device unique ID */
  udid: string;
};
export type PostV1DevicesRegisterByUdidApiResponse = unknown;
export type PostV1DevicesRegisterByUdidApiArg = {
  /** Device unique ID */
  udid: string;
  body: {
    csr: string;
    local_name: string;
  };
};
export type PatchV1DevicesByDeviceIdAndMonitorIdActivateApiResponse = unknown;
export type PatchV1DevicesByDeviceIdAndMonitorIdActivateApiArg = {
  /** Device ID */
  deviceId: number;
  /** Monitor ID */
  monitorId: number;
  body: {
    /** Enabled status */
    enabled: boolean;
  };
};
export type FetchMonitorsApiResponse = /** status 200 OK */ Monitor[];
export type FetchMonitorsApiArg = {
  includes?: ('enabled_devices' | 'devices')[];
};
export type PostV1MonitorsApiResponse = unknown;
export type PostV1MonitorsApiArg = {
  body: {
    nickname?: string;
    earzz_uid?: string;
  };
};
export type PatchV1MonitorsByIdApiResponse = unknown;
export type PatchV1MonitorsByIdApiArg = {
  /** Monitor ID */
  id: number;
  body: {
    nickname?: string;
    mic?: boolean;
    wifi_signal?: number;
    battery_level?: number;
    earzz_uid?: string;
  };
};
export type DeleteV1MonitorsByIdApiResponse = unknown;
export type DeleteV1MonitorsByIdApiArg = {
  id: number;
};
export type PutV1MonitorsByIdSoundsApiResponse = unknown;
export type PutV1MonitorsByIdSoundsApiArg = {
  /** Monitor ID */
  id: number;
  body: {
    /** Sound IDs */
    soundIds: number[];
  };
};
export type GetV1MonitorsByIdSoundsApiResponse = unknown;
export type GetV1MonitorsByIdSoundsApiArg = {
  /** Monitor ID */
  id: number;
};
export type FetchMonitorRoutineApiResponse = unknown;
export type FetchMonitorRoutineApiArg = {
  /** Monitor ID */
  id: number;
};
export type PatchMonitorRoutineApiResponse = unknown;
export type PatchMonitorRoutineApiArg = {
  id: string;
  body: {
    isActive: boolean;
    movement: boolean;
    start?: number;
    end?: number;
    timeZone: string;
    startTime?: string;
    endTime?: string;
    movement_sensitivity?: 'low' | 'medium' | 'high';
  };
};
export type PutV1MonitorsByIdDeviceApiResponse = unknown;
export type PutV1MonitorsByIdDeviceApiArg = {
  /** Monitor ID */
  id: number;
  body: {
    MicThresh?: 66 | 58;
    /** Indicates if the device is optimised for battery usage */
    battery_optimised?: boolean;
  };
};
export type PatchV1MonitorsByIdLedApiResponse = unknown;
export type PatchV1MonitorsByIdLedApiArg = {
  /** Monitor ID */
  id: number;
  body: {
    LedMax: 5 | 50;
  };
};
export type PatchV1MonitorsByIdNotificationFrequencyApiResponse = unknown;
export type PatchV1MonitorsByIdNotificationFrequencyApiArg = {
  /** Monitor ID */
  id: number;
  body: {
    /** Notification Frequency (2s, 10s, 30s, 1m, 2m, 5m, 30m) */
    notificationFrequency: string;
  };
};
export type GetV1MonitorsExistsByEarzzUidApiResponse = unknown;
export type GetV1MonitorsExistsByEarzzUidApiArg = {
  /** The name of the device to check */
  earzzUid: string;
};
export type FetchMonitorAvailableFirmwaresApiResponse =
  /** status 200 Default Response */ {
    update_available: boolean;
    firmwares: {
      version: string;
      track: string;
    }[];
  };
export type FetchMonitorAvailableFirmwaresApiArg = {
  track?: 'beta' | 'release';
  monitorId: string;
};
export type FetchLatestMonitorFirmwareUpdateApiResponse =
  /** status 200 Default Response */ {
    id: number;
    monitor_id: number;
    firmware: {
      version: string;
      track: string;
    } | null;
    status:
      | 'queued'
      | 'in-progress'
      | 'cancelled'
      | 'succeeded'
      | 'failed'
      | 'timed-out'
      | 'removed';
    created_at: string;
    updated_at: string;
  };
export type FetchLatestMonitorFirmwareUpdateApiArg = {
  monitorId: string;
};
export type UpdateMonitorFirmwareApiResponse =
  /** status 200 Default Response */ {
    id: number;
    monitor_id: number;
    firmware: {
      version: string;
      track: string;
    } | null;
    status:
      | 'queued'
      | 'in-progress'
      | 'cancelled'
      | 'succeeded'
      | 'failed'
      | 'timed-out'
      | 'removed';
    created_at: string;
    updated_at: string;
  };
export type UpdateMonitorFirmwareApiArg = {
  monitorId: string;
  body: {
    version?: string;
    track?: 'beta' | 'release';
  };
};
export type FetchMonitorPhysicalAttendanceApiResponse =
  /** status 200 Default Response */ {
    supported: boolean;
    enabled?: boolean;
    snooze_period?: string | null;
  };
export type FetchMonitorPhysicalAttendanceApiArg = {
  monitorId: string;
};
export type PatchMonitorPhysicalAttendanceApiResponse =
  /** status 200 Default Response */ {
    supported: boolean;
    enabled?: boolean;
    snooze_period?: string | null;
  };
export type PatchMonitorPhysicalAttendanceApiArg = {
  monitorId: string;
  body: {
    enabled?: boolean;
    snooze_period?: string | null;
  };
};
export type DisableMonitorPhysicalAttendanceApiResponse =
  /** status 200 Default Response */ {
    supported: boolean;
    enabled?: boolean;
    snooze_period?: string | null;
  };
export type DisableMonitorPhysicalAttendanceApiArg = {
  monitorId: string;
};
export type PostV1MonitorsByMonitorIdNoteEventsApiResponse = unknown;
export type PostV1MonitorsByMonitorIdNoteEventsApiArg = {
  /** Monitor ID */
  monitorId: number;
  body: {
    /** User-written note about a monitor */
    note: string;
  };
};
export type GetV1SettingsApiResponse = unknown;
export type GetV1SettingsApiArg = void;
export type PatchV1SettingsApiResponse = unknown;
export type PatchV1SettingsApiArg = void;
export type PutV1SettingsPasswordApiResponse = unknown;
export type PutV1SettingsPasswordApiArg = {
  body: {
    /** Old Password (8-32 chars) */
    oldPassword: string;
    /** New Password (8-32 chars) */
    newPassword: string;
  };
};
export type PatchV1SettingsDetailsApiResponse = unknown;
export type PatchV1SettingsDetailsApiArg = {
  body: {
    /** Full Name */
    fullName?: string;
    phoneNumber?: string;
  };
};
export type PatchV1SettingsSoundReportApiResponse = unknown;
export type PatchV1SettingsSoundReportApiArg = {
  body: {
    /** Flag to receive sound reports (true or false) */
    receiveSoundReport: boolean;
  };
};
export type DeleteV1SettingsDeactivateApiResponse = unknown;
export type DeleteV1SettingsDeactivateApiArg = void;
export type GetV1EventsApiResponse = /** status 200 Default Response */ {
  attendance_obj?: null | AttendanceResponse;
  [key: string]: any;
}[];
export type GetV1EventsApiArg = {
  /** Cursor for pagination, based on the ID of the sound event */
  cursor?: any;
  /** Limit the number of sound events returned */
  limit?: any;
  /** Comma-separated list of monitor IDs to filter sound events */
  monitorIds?: any;
  /** Comma-separated list of relations to fetch eagerly. Available options: attendance_obj */
  includes?: any;
};
export type GetV1EventsByIdFeedbackApiResponse = unknown;
export type GetV1EventsByIdFeedbackApiArg = {
  /** Event ID */
  id: number;
};
export type PostV1EventsByIdFeedbackApiResponse = unknown;
export type PostV1EventsByIdFeedbackApiArg = {
  /** Event ID */
  id: number;
  body: {
    message?: string;
    valid: boolean;
    actualSoundId?: number;
    actualSoundName?: string;
  };
};
export type FetchSoundEventAttendanceApiResponse =
  /** status 200 Default Response */ AttendanceResponse;
export type FetchSoundEventAttendanceApiArg = {
  /** Event ID */
  eventId: number;
};
export type PatchSoundEventAttendanceApiResponse =
  /** status 200 Default Response */ AttendanceResponse;
export type PatchSoundEventAttendanceApiArg = {
  /** Event ID */
  eventId: number;
  body: null | {
    /** The attendance status. */
    status?: 'not_attended' | 'attending' | 'attended';
    /** The attendance notes. */
    note?: string | null;
  };
};
export type PostV1DebugSoundsClassifyApiResponse = unknown;
export type PostV1DebugSoundsClassifyApiArg = {
  body: {
    monitorId: number;
    sound: Blob;
  };
};
export type PostV1AdminDebugSoundsClassifyApiResponse = unknown;
export type PostV1AdminDebugSoundsClassifyApiArg = {
  body: {
    monitorId: number;
    sound: Blob;
  };
};
export type PostV1WebhooksFoxyTransactionApiResponse = unknown;
export type PostV1WebhooksFoxyTransactionApiArg = {
  'foxy-webhook-event': string;
  body: object;
};
export type PostV1WebhooksFoxyCustomerApiResponse = unknown;
export type PostV1WebhooksFoxyCustomerApiArg = {
  'foxy-webhook-event': string;
  body: object;
};
export type FetchMonitorEventAttendanceApiResponse =
  /** status 200 Default Response */ AttendanceResponse;
export type FetchMonitorEventAttendanceApiArg = {
  /** Event ID */
  eventId: number;
};
export type PatchMonitorEventAttendanceApiResponse =
  /** status 200 Default Response */ AttendanceResponse;
export type PatchMonitorEventAttendanceApiArg = {
  /** Event ID */
  eventId: number;
  body: null | {
    /** The attendance status. */
    status?: 'not_attended' | 'attending' | 'attended';
    /** The attendance notes. */
    note?: string | null;
  };
};
export type FetchEventLogsApiResponse = /** status 200 Default Response */ {
  next_cursor?: string | null;
  data?: (SoundEventLogResponse | MonitorEventLogResponse)[];
};
export type FetchEventLogsApiArg = {
  /** Cursor for pagination. Use the same value obtained from next_cursor. */
  cursor?: string;
  /** Limit the number of log returned */
  limit?: number;
  /** Monitor IDs to filter logs */
  monitorIds?: number[];
  /** Monitor nicknames to filter logs */
  monitorNicknames?: string[];
  /** Sound IDs to filter logs */
  soundIds?: number[];
  /** Sound slugs to filter logs */
  soundSlugs?: string[];
  /** Type of logs to filter */
  types?: ('MonitorEvent' | 'SoundEvent')[];
  /** Monitor event types to filter logs */
  monitorEventTypes?: (
    | 'online'
    | 'offline'
    | 'firmware-updated'
    | 'battery-low'
    | 'battery-critical'
    | 'powered-on'
    | 'powered-off'
    | 'plugged'
    | 'unplugged'
    | 'physical-attendance'
    | 'note'
  )[];
  /** List of relations to fetch eagerly. */
  includes?: ('attendance' | 'sound' | 'monitor')[];
};
export type GetIftttV1StatusApiResponse = unknown;
export type GetIftttV1StatusApiArg = void;
export type PostIftttV1TestSetupApiResponse = unknown;
export type PostIftttV1TestSetupApiArg = void;
export type PostIftttV1TriggersNewSoundEventApiResponse = unknown;
export type PostIftttV1TriggersNewSoundEventApiArg = {
  body: {
    trigger_identity: string;
    limit?: number;
    triggerFields: {
      monitor_id: string;
      sound_id: string;
    };
  };
};
export type PostIftttV1TriggersNewSoundEventFieldsSoundIdOptionsApiResponse =
  unknown;
export type PostIftttV1TriggersNewSoundEventFieldsSoundIdOptionsApiArg = void;
export type PostIftttV1TriggersNewSoundEventFieldsMonitorIdOptionsApiResponse =
  unknown;
export type PostIftttV1TriggersNewSoundEventFieldsMonitorIdOptionsApiArg = void;
export type DeleteIftttV1TriggersNewSoundEventTriggerIdentityByTriggerIdentityApiResponse =
  unknown;
export type DeleteIftttV1TriggersNewSoundEventTriggerIdentityByTriggerIdentityApiArg =
  {
    /** Trigger identity that is no longer valid. */
    triggerIdentity: string;
  };
export type GetIftttV1UserInfoApiResponse = unknown;
export type GetIftttV1UserInfoApiArg = void;
export type FetchStatisticsMonitorOptionsApiResponse =
  /** status 200 Default Response */ {
    monitor_options: {
      key: string;
      value: string;
    }[];
  };
export type FetchStatisticsMonitorOptionsApiArg = {
  monitorId?: string;
  monitorIds?: string[];
  soundSlug?: string;
  soundSlugs?: string[];
  dateRange?: string[];
  includesExtras?: boolean;
};
export type FetchStatisticsSoundOptionsApiResponse =
  /** status 200 Default Response */ {
    sound_options: {
      key: string;
      value: string;
      svg_icon?: string | null;
    }[];
  };
export type FetchStatisticsSoundOptionsApiArg = {
  monitorId?: string;
  monitorIds?: string[];
  soundSlug?: string;
  soundSlugs?: string[];
  dateRange?: string[];
  includesExtras?: boolean;
};
export type FetchStatisticsDateRangeOptionsApiResponse =
  /** status 200 Default Response */ {
    date_range: (string | null)[];
  };
export type FetchStatisticsDateRangeOptionsApiArg = {
  monitorId?: string;
  monitorIds?: string[];
  soundSlug?: string;
  soundSlugs?: string[];
  dateRange?: string[];
  includesExtras?: boolean;
};
export type FetchStatisticsRoutineOptionsApiResponse =
  /** status 200 Default Response */ {
    routine: {
      time_zone?: string;
      start_time?: string;
      end_time?: string;
    } | null;
  };
export type FetchStatisticsRoutineOptionsApiArg = {
  monitorId?: string;
};
export type FetchStatisticsApiResponse = /** status 200 Default Response */ {
  query: {
    /** The date range in which statistics data will be compiled from. If not specified, a default will be provided. */
    date_range?: {
      from?: string;
      to?: string;
    };
    /** Specify options that affect how the date/time is interpreted in the query. */
    date_options?: {
      time_zone?: string;
      start_of_day?: string;
      granularity?: '1m' | '5m' | '1h' | '1d' | '1w';
    };
    /** An array of filters that will apply when querying for statistics. */
    filters?: {
      /** The name of the field to filter. Available options: monitor_id, sound_slug, datetime.hour. */
      field?: string;
      /** Comparison operator. Available options: =, <=, >=, <, >, in_hour_range. Not all operators available for all fields. */
      op?: string;
      /** The value to compare against. */
      value?: number | number | string | boolean | (number | string)[];
    }[];
    /** A measure is the name of the data field for specifying what statistical figure to fetch. For example, events.count is the number of sound events. A measure usually appears as the Y-axis in a simple bar chart. Available options: events.count, events.average, attendance.count, attendance.average. */
    measures: (
      | {
          [key: string]: any;
        }
      | string
    )[];
    /** A dimension compares a measure across a timeframe or different categories. For example, date_time.hour is the hour part of the sound event time. A dimension usually appears as the X-axis in a simple bar chart. Available options: date_time.hour. */
    dimensions?: (
      | {
          [key: string]: any;
        }
      | string
    )[];
  };
  data?: {
    [key: string]: any;
  }[];
};
export type FetchStatisticsApiArg = {
  body: {
    /** The date range in which statistics data will be compiled from. If not specified, a default will be provided. */
    date_range?: {
      from?: string;
      to?: string;
    };
    /** Specify options that affect how the date/time is interpreted in the query. */
    date_options?: {
      time_zone?: string;
      start_of_day?: string;
      granularity?: '1m' | '5m' | '1h' | '1d' | '1w';
    };
    /** An array of filters that will apply when querying for statistics. */
    filters?: {
      /** The name of the field to filter. Available options: monitor_id, sound_slug, datetime.hour. */
      field?: string;
      /** Comparison operator. Available options: =, <=, >=, <, >, in_hour_range. Not all operators available for all fields. */
      op?: string;
      /** The value to compare against. */
      value?: number | number | string | boolean | (number | string)[];
    }[];
    /** A measure is the name of the data field for specifying what statistical figure to fetch. For example, events.count is the number of sound events. A measure usually appears as the Y-axis in a simple bar chart. Available options: events.count, events.average, attendance.count, attendance.average. */
    measures: (
      | {
          [key: string]: any;
        }
      | string
    )[];
    /** A dimension compares a measure across a timeframe or different categories. For example, date_time.hour is the hour part of the sound event time. A dimension usually appears as the X-axis in a simple bar chart. Available options: date_time.hour. */
    dimensions?: (
      | {
          [key: string]: any;
        }
      | string
    )[];
  };
};
export type Device = {
  id: number;
  user_id: number | null;
  udid: string;
  app_version?: string | null;
  fcm_push_token?: string | null;
  name?: string | null;
  model?: string | null;
  platform?: string | null;
  local_name?: string | null;
};
export type MonitorDeviceInMonitorQuery = {
  id: number;
  user_id: number | null;
  udid: string;
  app_version?: string | null;
  fcm_push_token?: string | null;
  name?: string | null;
  model?: string | null;
  platform?: string | null;
  local_name?: string | null;
  enabled: boolean;
};
export type Monitor = {
  id: number;
  user_id?: number | null;
  earzz_uid: string;
  nickname: string;
  firmware_version?: string | null;
  model_number?: string | null;
  wifi_signal?: number | null;
  power?: boolean | null;
  created_at: string;
  updated_at: string;
  battery_level?: number | null;
  battery_optimised?: boolean | null;
  charging?: boolean | null;
  mic?: boolean | null;
  mic_threshold?: number | null;
  led?: number | null;
  notification_frequency?: string | null;
  online?: boolean | null;
  sounds?: {
    id: number;
    active?: boolean | null;
    priority?: number | null;
    slug?: string | null;
    name: string;
    description?: string | null;
    svg_icon?: string | null;
    created_at: string;
    updated_at: string;
  }[];
  routine?: {
    id: number;
    isActive: boolean;
    start: number;
    end: number;
    timeZone: string;
    monitor_id?: number | null;
    created_at: string;
    updated_at: string;
    movement?: boolean | null;
    movement_sensitivity?: 'low' | 'medium' | 'high';
    startTime: string;
    endTime: string;
  } | null;
  devices?: MonitorDeviceInMonitorQuery[];
  enabled_devices?: MonitorDeviceInMonitorQuery[];
  physical_attendance?: {
    supported: boolean;
    enabled?: boolean;
    snooze_period?: string | null;
  };
  metadata?: {
    [key: string]: any;
  } | null;
};
export type AttendanceResponse = {
  id: number;
  user_id: number;
  /** The attendance status. */
  status: 'not_attended' | 'attending' | 'attended';
  /** The attendance notes. */
  note: string | null;
  device_id?: number | null;
  created_at: string;
  updated_at: string;
  created_by?: number | null;
  updated_by?: number | null;
};
export type AttendanceResponse2 = {
  id: number;
  user_id: number;
  /** The attendance status. */
  status: 'not_attended' | 'attending' | 'attended';
  /** The attendance notes. */
  note: string | null;
  device_id?: number | null;
  created_at: string;
  updated_at: string;
  created_by?: number | null;
  updated_by?: number | null;
};
export type Monitor2 = {
  id: number;
  user_id?: number | null;
  earzz_uid: string;
  nickname: string;
  firmware_version?: string | null;
  model_number?: string | null;
  wifi_signal?: number | null;
  power?: boolean | null;
  created_at: string;
  updated_at: string;
  battery_level?: number | null;
  battery_optimised?: boolean | null;
  charging?: boolean | null;
  mic?: boolean | null;
  mic_threshold?: number | null;
  led?: number | null;
  notification_frequency?: string | null;
  online?: boolean | null;
  sounds?: {
    id: number;
    active?: boolean | null;
    priority?: number | null;
    slug?: string | null;
    name: string;
    description?: string | null;
    svg_icon?: string | null;
    created_at: string;
    updated_at: string;
  }[];
  routine?: {
    id: number;
    isActive: boolean;
    start: number;
    end: number;
    timeZone: string;
    monitor_id?: number | null;
    created_at: string;
    updated_at: string;
    movement?: boolean | null;
    movement_sensitivity?: 'low' | 'medium' | 'high';
    startTime: string;
    endTime: string;
  } | null;
  devices?: MonitorDeviceInMonitorQuery[];
  enabled_devices?: MonitorDeviceInMonitorQuery[];
  physical_attendance?: {
    supported: boolean;
    enabled?: boolean;
    snooze_period?: string | null;
  };
  metadata?: {
    [key: string]: any;
  } | null;
};
export type SoundEventLogResponse = {
  sound_event_id: number;
  user_id: number;
  type: 'SoundEvent';
  monitor_id: number;
  monitor_nickname: string;
  created_at: string;
  updated_at: string;
  attendance_status: string;
  attendance_allowed: boolean;
  notification_sent: boolean;
  sound_id: number;
  sound_slug: string;
  attendance?: null | AttendanceResponse2;
  monitor?: null | Monitor2;
  sound?: null | {
    [key: string]: any;
  };
};
export type MonitorEventLogResponse = {
  monitor_event_id: number;
  user_id: number;
  type: 'MonitorEvent';
  monitor_id: number;
  monitor_nickname: string;
  created_at: string;
  updated_at: string;
  attendance_status: string;
  attendance_allowed: boolean;
  notification_sent: boolean;
  monitor_event_type:
    | 'online'
    | 'offline'
    | 'firmware-updated'
    | 'battery-low'
    | 'battery-critical'
    | 'powered-on'
    | 'powered-off'
    | 'plugged'
    | 'unplugged'
    | 'physical-attendance'
    | 'note';
  note: string | null;
  attendance?: null | AttendanceResponse2;
  monitor?: null | Monitor2;
};
