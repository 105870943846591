import { captureException } from '@sentry/react';
import { t } from 'i18next';
import { useEffect, useMemo } from 'react';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';

import { Curtain } from '../components/Curtain';

export const ErrorScreen = () => {
  const error = useRouteError();

  const [title, text] = useMemo(() => {
    if (isRouteErrorResponse(error)) {
      switch (error.status) {
        case 404:
          return [undefined, t('Error.status.404.text')];
      }
    }

    return [undefined, undefined];
  }, [error]);

  useEffect(() => {
    if (isRouteErrorResponse(error) && error.status == 404) {
      return;
    }
    captureException(error);
  }, [error]);

  return <Curtain act="error" title={title} text={text} />;
};
