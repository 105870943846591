import { FormControl } from '@mui/base';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useLoginMutation } from '../api/backendApi';
import { Logo } from '../components/Logo';
import { Button } from '../components/ui/Button';
import { Input } from '../components/ui/Input';
import { PasswordInput } from '../components/ui/PasswordInput';
import { login as loginAction } from '../store/auth';
import { userHasRequiredPlan } from '../utils/auth';

interface LoginFormInputs {
  email: string;
  password: string;
}

export const LoginScreen = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<LoginFormInputs>();
  const dispatch = useDispatch();

  const [login, { isLoading }] = useLoginMutation();
  const handleLogin = useCallback(
    async (inputs: LoginFormInputs) => {
      try {
        const { token, user } = await login({
          body: {
            ...inputs,
            udid: '-'
          }
        }).unwrap();

        if (!userHasRequiredPlan(user)) {
          alert(t('login.error.requiredPlan'));
          return;
        }

        dispatch(loginAction({ token, user }));
        navigate('/');
      } catch (err: unknown) {
        // TODO: fix API transformResponseError to return an error object
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        alert((err as any).data?.message);
      }
    },
    [dispatch, login, navigate, t]
  );

  return (
    <div className="flex w-full flex-col items-center gap-y-6 sm:mt-[-80px] sm:w-[588px]">
      <header className="flex w-[414px] flex-col gap-y-2">
        <Logo />
        <div className="px-2 text-center text-xl">
          <Trans i18nKey="login.hero" />
        </div>
      </header>
      <main className="flex w-full flex-col gap-y-4 bg-white py-12 sm:px-11">
        <h1 className="text-center text-3xl">
          <Trans i18nKey="login.title" />
        </h1>
        <form
          onSubmit={handleSubmit(handleLogin)}
          className="mx-6 flex flex-col gap-y-6 sm:mx-16"
        >
          <FormControl className="flex flex-col gap-y-2">
            <label htmlFor="email">
              <Trans i18nKey="login.email.label" />
            </label>
            <Input
              id="email"
              type="email"
              placeholder={t('login.email.placeholder')}
              autoFocus
              {...register('email', { required: true })}
            />
            {errors.email && (
              <span className="text-error">
                <Trans i18nKey="login.email.required" />
              </span>
            )}
          </FormControl>
          <FormControl className="flex flex-col gap-y-2">
            <label htmlFor="password">
              <Trans i18nKey="login.password.label" />
            </label>
            <PasswordInput
              id="password"
              placeholder={t('login.password.placeholder')}
              {...register('password', { required: true })}
            />
            {/* errors will return when field validation fails  */}
            {errors.password && (
              <span className="text-error">
                <Trans i18nKey="login.password.required" />
              </span>
            )}
          </FormControl>

          <Button type="submit" className="mt-8" disabled={isLoading}>
            <Trans i18nKey="login.action" />
          </Button>
        </form>
      </main>
    </div>
  );
};
