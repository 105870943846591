import { PropsWithChildren, useCallback } from 'react';
import { Trans } from 'react-i18next';

import { cn } from '../lib/utils';
import { StatisticsQueryDateOptionsGranularity } from '../models/Granularity';

const granularities: StatisticsQueryDateOptionsGranularity[] = [
  '1m',
  '5m',
  '1h',
  '1d',
  '1w'
];

interface ChartIntervalSelectorItemProps extends PropsWithChildren {
  value: StatisticsQueryDateOptionsGranularity;
  onSelect: (value: StatisticsQueryDateOptionsGranularity) => void;
  selected?: boolean;
  disabled?: boolean;
}

export const ChartIntervalSelectorItem = ({
  value,
  selected = false,
  disabled = false,
  onSelect
}: ChartIntervalSelectorItemProps) => {
  const handleSelect = useCallback(() => {
    onSelect(value);
  }, [onSelect, value]);
  return (
    <li
      className={cn('px-1 ', {
        'font-bold': selected,
        'opacity-50': disabled,
        'cursor-pointer underline': !disabled && !selected
      })}
      onClick={disabled ? undefined : handleSelect}
    >
      {value}
    </li>
  );
};

interface ChartIntervalSelectorProps {
  value: StatisticsQueryDateOptionsGranularity | undefined;
  onChange: (value: StatisticsQueryDateOptionsGranularity) => void;
  allowedChoices?: StatisticsQueryDateOptionsGranularity[];
  choices?: StatisticsQueryDateOptionsGranularity[];
}

export const ChartIntervalSelector = (props: ChartIntervalSelectorProps) => {
  const {
    value,
    onChange,
    choices = granularities,
    allowedChoices = choices
  } = props;
  return (
    <div className="flex">
      <div className="pr-1">
        <Trans i18nKey="components.ChartIntervalSelector.label" />
      </div>
      <ul className="flex">
        {choices.map(g => (
          <ChartIntervalSelectorItem
            key={g}
            value={g}
            onSelect={onChange}
            selected={g == value}
            disabled={!allowedChoices.includes(g)}
          />
        ))}
      </ul>
    </div>
  );
};
