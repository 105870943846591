import { FC, PropsWithChildren } from 'react';
import { Outlet } from 'react-router-dom';

export interface AuthLayoutProps extends PropsWithChildren {}

export const AuthLayout: FC<AuthLayoutProps> = ({ children }) => {
  return (
    <div className="flex min-h-screen min-w-[100vw] flex-col items-center justify-center bg-auth-background">
      {children ?? <Outlet />}
    </div>
  );
};
