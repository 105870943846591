import { useCallback } from 'react';
import { BaseAxisProps } from 'recharts/types/util/types';

type Formatter = Exclude<BaseAxisProps['tickFormatter'], undefined>;

interface UseRechartsCategoryAxisFormatterArgs {
  labelMaxLength?: number;
}

export const useRechartsCategoryAxisFormatter = ({
  labelMaxLength
}: UseRechartsCategoryAxisFormatterArgs): Formatter => {
  const formatter = useCallback(
    (value: unknown) => {
      let stringValue = typeof value == 'string' ? value : String(value);
      if (labelMaxLength && stringValue.length > labelMaxLength) {
        stringValue = `${stringValue.substring(0, labelMaxLength)}…`;
      }
      return stringValue;
    },
    [labelMaxLength]
  );
  return formatter;
};
