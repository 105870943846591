import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { BaseAxisProps } from 'recharts/types/util/types';

type Formatter = Exclude<BaseAxisProps['tickFormatter'], undefined>;

export const useRechartsYAxisFormatter = (): Formatter => {
  const intl = useIntl();

  const formatter = useCallback(
    (value: unknown) => {
      if (typeof value === 'number') {
        return intl.formatNumber(value, { maximumSignificantDigits: 2 });
      }
      return String(value);
    },
    [intl]
  );
  return formatter;
};
