import { ReactElement } from 'react';

import { OptionProps, OptionSymbol } from './Option';

export const isOptionElement = (
  element: ReactElement
): element is ReactElement<OptionProps<string>> => {
  if (typeof element.type === 'string') {
    return element.type === 'Option';
  }
  return OptionSymbol in element.type;
};
