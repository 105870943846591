import { useMemo } from 'react';

import { Option, OptionProps } from './Option';

interface TimeRangeOptionProps extends Omit<OptionProps<string>, 'value'> {
  from: string;
  to: string;
}

export const TimeRangeOption = (props: TimeRangeOptionProps) => {
  const { from, to, ...others } = props;

  const value = useMemo(() => {
    return `${from}-${to}`;
  }, [from, to]);

  return <Option {...others} value={value} />;
};

export type { OptionProps };
