interface ChartNoticeProps {
  x: number;
  y: number;
  label: string;
}

// NOTE: This is not a React component.
// See https://github.com/recharts/recharts/issues/412
export const getChartNotice = ({ x, y, label }: ChartNoticeProps) => {
  const lines = label.split('\n');
  return (
    <text
      y={y}
      fill="red"
      textAnchor="left"
      dominantBaseline="central"
      key="text"
    >
      {lines.map((line, i) => (
        <tspan key={i} x={x} dy={20 * i}>
          {line}
        </tspan>
      ))}
    </text>
  );
};
