import {
  useFetchMonitorsQuery,
  useFetchUserDevicesQuery
} from '../../api/backendApi';
import { Curtain } from '../../components/Curtain';
import { StatusScreenContent } from './Content';

export const StatusScreen = () => {
  // TODO: Error handling
  const { data: monitors, isLoading: isLoadingMonitors } =
    useFetchMonitorsQuery(
      { includes: ['enabled_devices'] },
      { refetchOnMountOrArgChange: true, refetchOnFocus: true }
    );
  const { data: devices, isLoading: isLoadingDevices } =
    useFetchUserDevicesQuery();
  if (isLoadingMonitors || isLoadingDevices) {
    return <Curtain act="loading" />;
  }

  if (monitors?.length == 0) {
    return <Curtain act="empty" text="" />;
  }

  return (
    <StatusScreenContent monitors={monitors ?? []} devices={devices ?? []} />
  );
};
