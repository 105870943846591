export const parseTimeString = (timestr: string): Date => {
  if (typeof timestr !== 'string') {
    return new Date(undefined!);
  }

  const components = timestr.split(':').map(Number);
  if (
    components.length > 3 ||
    components.filter(n => Number.isNaN(n)).length > 0
  ) {
    return new Date(undefined!);
  }

  const refDate = new Date(0);

  refDate.setHours(components[0], components[1], components[2]);
  return refDate;
};
