import { createBrowserRouter } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import { Curtain } from './components/Curtain';
import { TODO } from './components/TODO';
import { AuthLayout } from './layouts/AuthLayout';
import { Layout } from './layouts/Layout';
import { RequireUserRoute } from './routes/RequireUserRoute';
import { DataViewMonitorScreen } from './screens/DataViewMonitorScreen';
import { DataViewSoundScreen } from './screens/DataViewSoundScreen';
import { DevelopmentMonitorFirmwareDemoScreen } from './screens/DevelopmentMonitorFirmwareDemoScreen';
import { DevelopmentSoundColorScreen } from './screens/DevelopmentSoundColor';
import { ErrorScreen } from './screens/ErrorScreen';
import { LoginScreen } from './screens/LoginScreen';
import { StatusScreen } from './screens/StatusScreen';

export const router = createBrowserRouter([
  {
    element: <AuthLayout />,
    errorElement: (
      <AuthLayout>
        <ErrorScreen />
      </AuthLayout>
    ),
    children: [
      {
        path: '/login',
        element: <LoginScreen />
      }
    ]
  },
  {
    path: '/_dev/sounds',
    element: <DevelopmentSoundColorScreen />
  },
  {
    path: '/',
    element: (
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <RequireUserRoute>
          <Layout />
        </RequireUserRoute>
      </QueryParamProvider>
    ),
    errorElement: (
      <RequireUserRoute>
        <Layout>
          <ErrorScreen />
        </Layout>
      </RequireUserRoute>
    ),
    children: [
      {
        path: '/',
        element: <StatusScreen />
      },
      {
        path: '/data/by-monitor',
        element: <DataViewMonitorScreen />
      },
      {
        path: '/data/by-sound',
        element: <DataViewSoundScreen />
      },
      {
        path: '/insights/overall',
        element: (
          <TODO redirect={true}>
            <Curtain className="min-h-96" act="todo" />
          </TODO>
        )
      },
      {
        path: '/insights/by-monitor',
        element: (
          <TODO redirect={true}>
            <Curtain className="min-h-96" act="todo" />
          </TODO>
        )
      },
      {
        path: '/alerting',
        element: (
          <TODO redirect={true}>
            <Curtain className="min-h-96" act="todo" />
          </TODO>
        )
      },
      {
        path: '/account',
        element: (
          <TODO redirect={true}>
            <Curtain className="min-h-96" act="todo" />
          </TODO>
        )
      },
      {
        path: '/_dev/monitors/:monitorId',
        element: <DevelopmentMonitorFirmwareDemoScreen />
      }
    ]
  }
]);
