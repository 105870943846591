import { Input as BaseInput, InputProps } from '@mui/base/Input';
import * as React from 'react';

const Input = React.forwardRef<HTMLDivElement, InputProps>(
  ({ ...props }, ref) => {
    return (
      <BaseInput
        ref={ref}
        slotProps={{
          root: {
            className: 'flex py-1 rounded-xl bg-input-background'
          },
          input: {
            className:
              'grow bg-transparent p-3 text-sm placeholder:text-input-placeholder'
          }
        }}
        {...props}
      />
    );
  }
);
Input.displayName = 'Input';

export { Input };
export type { InputProps };
