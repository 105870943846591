import { Button } from '@mui/base/Button';
import { FormControl } from '@mui/base/FormControl';
import {
  isValidElement,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useMemo
} from 'react';
import type { FieldPath, FieldValues } from 'react-hook-form';
import { ControllerProps, useController } from 'react-hook-form';
import { Trans } from 'react-i18next';

import { cn } from '../../lib/utils';
import { Select } from '../ui/Select';
import { isOptionElement } from '../ui/utils';

export interface SelectFormControlProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends Omit<ControllerProps<TFieldValues, TName>, 'render'>,
    PropsWithChildren {
  label?: string | null;
  className?: string;
  placeholder?: string;
  readOnly?: boolean;
  multiple?: boolean;
}

const isReactFragment = (node: ReactNode): node is Iterable<ReactNode> => {
  return Symbol.iterator in Object(node);
};

export const SelectFormControl = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  props: SelectFormControlProps<TFieldValues, TName>
) => {
  const {
    children,
    label,
    className,
    placeholder,
    readOnly,
    multiple = false,
    ...others
  } = props;
  const { name } = others;
  const {
    field: { onChange, value, disabled }
  } = useController(others);

  const allOptions = useMemo(() => {
    if (!isReactFragment(children)) {
      return [];
    }

    return Array.from(children)
      .filter(isValidElement)
      .filter(isOptionElement)
      .map(element => element.props.value);
  }, [children]);

  const handleSelectAll = useCallback(() => {
    onChange(allOptions);
  }, [allOptions, onChange]);

  const handleDeselectAll = useCallback(() => {
    onChange([]);
  }, [onChange]);

  const shouldShowBatchSelectButtons = multiple && allOptions.length > 0;
  const batchSelectMode =
    Array.isArray(value) && value.length == allOptions.length
      ? 'deselect'
      : 'select';

  return (
    <FormControl
      className={cn(className, { 'opacity-50': disabled || readOnly })}
    >
      <label htmlFor={name}>{label}</label>
      <Select
        id={name}
        onValueChange={onChange}
        value={value}
        multiple={multiple}
        placeholder={placeholder}
        disabled={disabled || readOnly}
      >
        {shouldShowBatchSelectButtons ? (
          batchSelectMode == 'select' ? (
            <Button
              onClick={handleSelectAll}
              className={cn('w-full p-4 text-left', {})}
            >
              <Trans i18nKey="components.forms.SelectFormControl.selectAll" />
            </Button>
          ) : (
            <Button
              onClick={handleDeselectAll}
              className={cn('w-full p-4 text-left', {})}
            >
              <Trans i18nKey="components.forms.SelectFormControl.deselectAll" />
            </Button>
          )
        ) : null}
        {children}
      </Select>
    </FormControl>
  );
};
