import { FC, PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';

import { useTODO, UseTODOOptions } from '../hooks/useTODO';

export type TODOProps = UseTODOOptions &
  PropsWithChildren & {
    redirect?: boolean;
  };

export const TODO: FC<TODOProps> = ({ children, redirect, ...opts }) => {
  const hide = useTODO(opts);
  if (hide) {
    if (redirect) {
      return <Navigate to="/" />;
    }
    return <></>;
  }

  return (
    <div className="border-2 border-dashed border-red-200">{children}</div>
  );
};
