import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable
} from '@tanstack/react-table';
import { FC } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import type { Monitor } from '../api/types';
import monitorIcon from '../assets/monitor-default.png';
import { cn } from '../lib/utils';
import { isNonNull } from '../utils/null';
import { MonitorStatusIndicator } from './MonitorStatusIndicator';
import { MonitorTableDevicesCell } from './MonitorTableDevicesCell';
import { MonitorTableKeyRequirementCell } from './MonitorTableKeyRequirement';

interface MonitorTableProps {
  data: Monitor[];
  className?: string;
}

const columnHelper = createColumnHelper<Monitor>();

const columns = [
  columnHelper.display({
    id: 'icon',
    cell: info => (
      <Link to={`/data/by-monitor?monitor_id=${info.row.original.id}`}>
        <img src={monitorIcon} className="max-w-9" alt="" />
      </Link>
    ),
    meta: {
      cellClassName: 'px-4'
    }
  }),
  columnHelper.accessor('nickname', {
    cell: info => (
      <Link to={`/data/by-monitor?monitor_id=${info.row.original.id}`}>
        {info.getValue()}
      </Link>
    ),
    header: () => <Trans i18nKey="components.MonitorTable.columns.name" />,
    meta: {
      cellClassName: 'text-sm text-monitorTable-foreground',
      headerClassName: 'min-w-32'
    }
  }),
  columnHelper.display({
    id: 'requirement',
    header: () => (
      <Trans i18nKey="components.MonitorTable.columns.requirement" />
    ),
    cell: info => (
      <MonitorTableKeyRequirementCell
        monitorId={String(info.row.original.id)}
        soundSlugs={
          info.row.original.sounds?.map(s => s.slug).filter(isNonNull) ?? []
        }
      />
    )
  }),
  columnHelper.accessor('enabled_devices', {
    cell: info => <MonitorTableDevicesCell devices={info.getValue() ?? []} />,
    header: () => <Trans i18nKey="components.MonitorTable.columns.devices" />,
    meta: {
      headerClassName: '',
      cellClassName: 'text-sm text-monitorTable-foreground'
    }
  }),
  columnHelper.accessor('online', {
    id: 'status',
    header: () => <Trans i18nKey="components.MonitorTable.columns.status" />,
    cell: info => <MonitorStatusIndicator monitor={info.row.original} />,
    meta: {
      headerClassName: 'max-w-40 pl-2.5'
    }
  })
];

export const MonitorTable: FC<MonitorTableProps> = ({ data, className }) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel()
  });

  return (
    <table className={className}>
      <thead>
        {table.getHeaderGroups().map(headerGroup => (
          <tr
            key={headerGroup.id}
            className="h-[50px] border-b-2 border-b-table-row"
          >
            {headerGroup.headers.map(header => (
              <th
                key={header.id}
                className={cn(
                  'text-left text-sm font-normal',
                  header.column.columnDef.meta?.headerClassName
                )}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map(row => (
          <tr key={row.id} className="h-[50px] border-b border-b-table-row">
            {row.getVisibleCells().map(cell => (
              <td
                key={cell.id}
                className={cn(
                  'align-middle',
                  cell.column.columnDef.meta?.cellClassName
                )}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
