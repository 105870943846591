import { Button as BaseButton, ButtonProps } from '@mui/base/Button';
import * as React from 'react';

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ ...props }, ref) => {
    return (
      <BaseButton
        ref={ref}
        slotProps={{
          root: {
            className:
              'rounded-lg p-4 bg-button-background text-base text-button-foreground'
          }
        }}
        {...props}
      />
    );
  }
);
Button.displayName = 'Button';

export { Button };
export type { ButtonProps };
