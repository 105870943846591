import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { backendApi } from '../api/backendApi';
import authReducer from './auth';
import counterReducer from './counter';
import { resetCacheMiddleware } from './resetCacheMiddleware';
import { sentryMiddleware } from './sentry';
import soundOptionsReducer, { soundOptionsAdapter } from './soundOptions';

const reducer = combineReducers({
  counter: counterReducer,
  auth: persistReducer(
    {
      key: 'auth',
      storage,
      whitelist: [
        'token',
        'isAuthenticated',
        'user' /* TODO: the user info should be fetched from an endpoint */
      ]
    },
    authReducer
  ),
  soundOptions: soundOptionsReducer,
  [backendApi.reducerPath]: backendApi.reducer
});

export const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST']
      }
    })
      .concat(backendApi.middleware)
      .concat(sentryMiddleware.middleware)
      .concat(resetCacheMiddleware.middleware)
});

setupListeners(store.dispatch);

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const soundOptionsSelectors =
  soundOptionsAdapter.getSelectors<RootState>(state => state.soundOptions);
