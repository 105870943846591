import { addDays, format as formatDate, isSameDay, parseISO } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ResponsiveContainer } from 'recharts';
import { StringParam, useQueryParams } from 'use-query-params';

import {
  useFetchMonitorsQuery,
  useFetchStatisticsQuery,
  useFetchStatisticsSoundOptionsQuery
} from '../../api/backendApi';
import { FetchStatisticsApiArg, Monitor } from '../../api/generated/backendApi';
import { ChartLoadingContainer } from '../../components/ChartLoadingContainer';
import { Curtain } from '../../components/Curtain';
import { useColorPreference } from '../../hooks/useColorPreference';
import { monitorIsOnline } from '../../models/Monitor';
import {
  CommaDelimitedDateRangeParam,
  CommaDelimitedTimeRangeParam,
  EnumParam
} from '../../utils/queryparams';
import {
  AcrossMonitorsChart,
  AcrossMonitorsChartProps
} from './AcrossMonitorsChart';
import {
  SoundFilterForm,
  SoundFilterFormInputs,
  SoundFilterFormInputsSchema
} from './FilterForm';
import {
  PerMonitorChart,
  PerMonitorChartDataItem,
  PerMonitorChartProps
} from './PerMonitorChart';

export const DataViewSoundScreen = () => {
  const { t } = useTranslation();
  const [queryParams, setQueryParams] = useQueryParams({
    sound_slug: StringParam,
    date_range: CommaDelimitedDateRangeParam,
    time_range: CommaDelimitedTimeRangeParam,
    graph: EnumParam<'count' | 'average'>(['count', 'average'])
  });

  const hourRangeFilters = useMemo(() => {
    const params = SoundFilterFormInputsSchema.safeParse(queryParams);
    if (!params.success) {
      return [];
    }

    const { from: start_time, to: end_time } = params.data.time_range;
    if (!start_time || !end_time) {
      return [];
    }

    const range = [start_time, end_time].map(t => Number(t.split(':')[0]));

    if (range[0] === range[1]) {
      return [];
    }

    return [
      {
        field: 'date_time.hour',
        op: 'in_hour_range',
        value: range
      }
    ];
  }, [queryParams]);

  const statQuery: FetchStatisticsApiArg['body'] | null = useMemo(() => {
    const params = SoundFilterFormInputsSchema.safeParse(queryParams);
    if (!params.success) {
      return null;
    }

    const { sound_slug, date_range, graph } = params.data;
    const { from, to } = date_range;

    return {
      date_range: {
        from: formatDate(from, 'yyyy-MM-dd'),
        to: formatDate(addDays(to, 1), 'yyyy-MM-dd')
      },
      date_options: {
        time_zone: 'Europe/London',
        start_of_day: queryParams.time_range?.from ?? '00:00'
      },
      filters: [
        {
          field: 'sound.slug',
          op: '=',
          value: sound_slug
        },
        ...hourRangeFilters
      ],
      measures: [
        graph == 'count'
          ? { events: 'events.count' }
          : { events: 'events.average' }
      ],
      dimensions: ['date_time.date']
    };
  }, [hourRangeFilters, queryParams]);

  const perMonitorQuery: FetchStatisticsApiArg['body'] | null = useMemo(() => {
    const params = SoundFilterFormInputsSchema.safeParse(queryParams);
    if (!params.success) {
      return null;
    }

    const { sound_slug, date_range, graph } = params.data;

    return {
      date_range: {
        from: formatDate(date_range.from, 'yyyy-MM-dd'),
        to: formatDate(addDays(date_range.to, 1), 'yyyy-MM-dd')
      },
      date_options: {
        time_zone: 'Europe/London',
        start_of_day: queryParams.time_range?.from ?? '00:00'
      },
      filters: [
        {
          field: 'sound_slug',
          op: '=',
          value: sound_slug
        },
        ...hourRangeFilters
      ],
      measures: [
        graph == 'count'
          ? { events: 'events.count' }
          : { events: 'events.average' }
      ],
      dimensions: ['monitor.id']
    };
  }, [hourRangeFilters, queryParams]);

  const handleFilterChange = useCallback(
    (values: SoundFilterFormInputs | null) => {
      setQueryParams(
        values ?? {
          sound_slug: undefined,
          date_range: undefined,
          time_range: undefined,
          graph: undefined
        }
      );
    },
    [setQueryParams]
  );

  const formValues = useMemo(() => {
    const { date_range, graph, sound_slug, time_range } = queryParams;
    return {
      date_range: date_range ?? undefined,
      time_range: time_range ?? undefined,
      graph: graph ?? undefined,
      sound_slug: sound_slug ?? undefined
    };
  }, [queryParams]);

  const { data, isFetching: chartDataIsFetching } = useFetchStatisticsQuery(
    {
      body: statQuery!
    },
    { skip: !statQuery }
  );

  const { data: perSoundData, isFetching: perMonitorChartDataIsFetching } =
    useFetchStatisticsQuery(
      {
        body: perMonitorQuery!
      },
      { skip: !perMonitorQuery }
    );

  const { data: selectedSoundName } = useFetchStatisticsSoundOptionsQuery(
    {},
    {
      selectFromResult: result => {
        const selectedSoundSlug = queryParams.sound_slug;
        return {
          ...result,
          data:
            result.data?.sound_options?.filter(
              o => o.key == selectedSoundSlug
            )?.[0]?.value ?? 'Unknown'
        };
      }
    }
  );

  const { getSoundColor } = useColorPreference();
  const selectedSoundColor = useMemo(() => {
    return getSoundColor(queryParams.sound_slug ?? '');
  }, [queryParams.sound_slug, getSoundColor]);

  const { data: monitors } = useFetchMonitorsQuery(
    {},
    {
      selectFromResult: result => {
        return {
          ...result,
          data: result.data?.reduce<Record<string, Monitor>>(
            (prev, monitor) => {
              prev[monitor.id] = monitor;
              return prev;
            },
            {}
          )
        };
      }
    }
  );

  const dateRangeTitle = useMemo(() => {
    if (!queryParams.date_range) {
      return '';
    }

    const { from, to } = queryParams.date_range;
    if (!from || !to) {
      return '';
    }

    if (isSameDay(from, to)) {
      return formatDate(from, 'dd MMM yyyy');
    }

    return `${formatDate(from, 'dd MMM yyyy')} - ${formatDate(to, 'dd MMM yyyy')}`;
  }, [queryParams.date_range]);

  const timeRangeTitle = useMemo(() => {
    if (!queryParams.time_range) {
      return '';
    }

    const { from, to } = queryParams.time_range;
    if (!from || !to) {
      return '';
    }

    return `${from} - ${to}`;
  }, [queryParams.time_range]);

  const acrossMonitorsYAxisTitle =
    queryParams.graph === 'count'
      ? t('DataViewSound.AcrossMonitorsChart.yAxis.count', {
          sound: selectedSoundName
        })
      : t('DataViewSound.AcrossMonitorsChart.yAxis.average', {
          sound: selectedSoundName
        });

  const perMonitorYAxisTitle =
    queryParams.graph === 'count'
      ? t('DataViewSound.PerMonitorChart.yAxis.count', {
          sound: selectedSoundName
        })
      : t('DataViewSound.PerMonitorChart.yAxis.average', {
          sound: selectedSoundName
        });

  const navigate = useNavigate();

  const handleChartSelectMonitor = useCallback(
    ({ monitor_id }: PerMonitorChartDataItem) => {
      const qs = new URLSearchParams();
      qs.append('monitor_id', monitor_id);
      if (queryParams.sound_slug) {
        qs.append('sound_slugs', queryParams.sound_slug ?? '');
      }

      let date_range = CommaDelimitedDateRangeParam.encode(
        queryParams.date_range
      );
      date_range = Array.isArray(date_range) ? date_range[0] : date_range;
      if (date_range) {
        qs.append('date_range', date_range);
      }

      let time_range = CommaDelimitedTimeRangeParam.encode(
        queryParams.time_range
      );
      time_range = Array.isArray(time_range) ? time_range[0] : time_range;
      if (time_range) {
        qs.append('time_range', time_range);
      }

      qs.append('graph', queryParams.graph ?? 'total');

      navigate({
        pathname: '/data/by-monitor',
        search: qs.toString()
      });
    },
    [
      navigate,
      queryParams.date_range,
      queryParams.graph,
      queryParams.sound_slug,
      queryParams.time_range
    ]
  );

  const [perMonitorChartProps, setPerMonitorChartProps] =
    useState<PerMonitorChartProps>();
  const [acrossMonitorsChartProps, setAcrossMonitorsChartProps] =
    useState<AcrossMonitorsChartProps>();

  useEffect(() => {
    if (perMonitorChartDataIsFetching) {
      return;
    }

    setPerMonitorChartProps({
      title: t('DataViewSound.PerMonitorChart.title', {
        sound: selectedSoundName
      }),
      subtitles: [dateRangeTitle, timeRangeTitle],
      data:
        perSoundData?.data?.map(({ monitor_id, events }) => {
          const monitor = monitors?.[monitor_id];
          return {
            monitor_nickname: monitor?.nickname ?? monitor_id,
            monitor_status:
              monitor && !monitorIsOnline(monitor)
                ? ('offline' as const)
                : ('online' as const),
            monitor_id,
            events: Number(events)
          };
        }) ?? [],
      yAxisTitle: perMonitorYAxisTitle,
      barColor: selectedSoundColor,
      chartType: queryParams.graph === 'count' ? 'total' : 'anomaly',
      onSelect: handleChartSelectMonitor
    });
  }, [
    dateRangeTitle,
    handleChartSelectMonitor,
    monitors,
    perMonitorChartDataIsFetching,
    perMonitorYAxisTitle,
    perSoundData?.data,
    queryParams.graph,
    selectedSoundColor,
    selectedSoundName,
    t,
    timeRangeTitle
  ]);

  useEffect(() => {
    if (chartDataIsFetching) {
      return;
    }

    setAcrossMonitorsChartProps({
      title: t('DataViewSound.AcrossMonitorsChart.title', {
        sound: selectedSoundName
      }),
      subtitles: [dateRangeTitle, timeRangeTitle],
      data:
        data?.data?.map(({ date_time_date, events }) => {
          const d = parseISO(date_time_date);
          return {
            date: formatDate(d, 'dd-MM-yyyy'),
            events: Number(events)
          };
        }) ?? [],
      yAxisTitle: acrossMonitorsYAxisTitle,
      barColor: selectedSoundColor,
      chartType: queryParams.graph === 'count' ? 'total' : 'anomaly'
    });
  }, [
    acrossMonitorsYAxisTitle,
    chartDataIsFetching,
    data?.data,
    dateRangeTitle,
    queryParams.graph,
    selectedSoundColor,
    selectedSoundName,
    t,
    timeRangeTitle
  ]);

  const content = (() => {
    if (!statQuery) {
      return <Curtain act="no-selection" className="min-h-96" />;
    }

    return (
      <div>
        <ChartLoadingContainer isLoading={perMonitorChartDataIsFetching}>
          <ResponsiveContainer width="100%" height={800}>
            {perMonitorChartProps ? (
              <PerMonitorChart {...perMonitorChartProps} />
            ) : (
              <></>
            )}
          </ResponsiveContainer>
        </ChartLoadingContainer>
        <ChartLoadingContainer isLoading={chartDataIsFetching}>
          <ResponsiveContainer
            width="100%"
            height={800}
            className="mt-12 border-t-2 pt-12"
          >
            {acrossMonitorsChartProps ? (
              <AcrossMonitorsChart {...acrossMonitorsChartProps} />
            ) : (
              <></>
            )}
          </ResponsiveContainer>
        </ChartLoadingContainer>
      </div>
    );
  })();

  return (
    <main className="mb-12 flex flex-col gap-y-6 p-9">
      <header>
        <h2 className="font-bold uppercase">
          <Trans i18nKey="DataViewSound.title" />
        </h2>
        <p>
          <Trans i18nKey="DataViewSound.subtitle" />
        </p>
      </header>
      <SoundFilterForm values={formValues} onChange={handleFilterChange} />
      {content}
    </main>
  );
};
