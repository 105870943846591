import { Menu } from '@mui/base/Menu';
import { MenuItem } from '@mui/base/MenuItem';
import { Trans } from 'react-i18next';

import iconAccount from '../assets/icon-account.svg';
import iconClock from '../assets/icon-clock.svg';
import iconHome from '../assets/icon-home.svg';
import { useTODO } from '../hooks/useTODO';
import { NavigationMenuItem } from './NavigationMenu/NavigationMenuItem';

interface NavigationProps {
  className?: string;
}

export const Navigation: React.FC<NavigationProps> = ({ className }) => {
  const hide = useTODO();

  return (
    <nav className={className}>
      <header className="flex h-[40px] items-center pl-4 text-xs uppercase">
        <Trans i18nKey="components.navigation.menu" />
      </header>
      <Menu>
        <NavigationMenuItem to="/" icon={iconHome}>
          <Trans i18nKey="components.navigation.status" />
        </NavigationMenuItem>
        <NavigationMenuItem icon={iconClock}>
          <Trans i18nKey="components.navigation.data-view" />
        </NavigationMenuItem>
        <MenuItem>
          <Menu>
            <NavigationMenuItem to="/data/by-sound" level={1}>
              <Trans i18nKey="components.navigation.data-view.by-sound" />
            </NavigationMenuItem>
            <NavigationMenuItem to="/data/by-monitor" level={1}>
              <Trans i18nKey="components.navigation.data-view.by-monitor" />
            </NavigationMenuItem>
          </Menu>
        </MenuItem>
        {!hide && (
          <>
            <NavigationMenuItem icon={iconClock}>
              <Trans i18nKey="components.navigation.insights" />
            </NavigationMenuItem>
            <MenuItem>
              <Menu>
                <NavigationMenuItem to="/insights/overall" level={1}>
                  <Trans i18nKey="components.navigation.insights.overall" />
                </NavigationMenuItem>
                <NavigationMenuItem to="/insights/by-monitor" level={1}>
                  <Trans i18nKey="components.navigation.insights.by-monitor" />
                </NavigationMenuItem>
              </Menu>
            </MenuItem>
            <NavigationMenuItem to="/alerting" icon={iconClock}>
              <Trans i18nKey="components.navigation.alerting" />
            </NavigationMenuItem>
            <NavigationMenuItem to="/account" icon={iconAccount}>
              <Trans i18nKey="components.navigation.account" />
            </NavigationMenuItem>
          </>
        )}
      </Menu>
    </nav>
  );
};
