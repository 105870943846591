import { FC, useMemo } from 'react';
import { Trans } from 'react-i18next';

import { temporaryIdentifyKeyRequirement } from '../utils/key-requirements';

interface MonitorTableKeyRequirementCellProps {
  monitorId: string;
  soundSlugs: string[];
}
export const MonitorTableKeyRequirementCell: FC<
  MonitorTableKeyRequirementCellProps
> = ({ monitorId, soundSlugs }) => {
  const keyRequirement = useMemo(() => {
    return temporaryIdentifyKeyRequirement(monitorId, soundSlugs);
  }, [monitorId, soundSlugs]);

  if (!keyRequirement) {
    return <></>;
  }

  return <Trans i18nKey={`models.KeyRequirement.${keyRequirement}`} />;
};
