import { Button } from '@mui/base/Button';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { FormControl } from '@mui/base/FormControl';
import { Popper } from '@mui/base/Popper';
import {
  addDays,
  format as formatDate,
  formatDistance,
  isSameDay,
  startOfDay,
  startOfMonth
} from 'date-fns';
import { useCallback, useMemo, useRef, useState } from 'react';
import type { FieldPath, FieldValues } from 'react-hook-form';
import { ControllerProps, useController } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { cn } from '../../lib/utils';
import { Calendar, CalendarProps } from '../ui/Calendar';

export type CalendarFormControlProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = Omit<ControllerProps<TFieldValues, TName>, 'render'> &
  Omit<CalendarProps, 'selected' | 'onSelect'> & {
    label?: string | null;
    className?: string;
    placeholder?: string;
    readOnly?: boolean;
  };

type NormalizedDateRange = [Date | undefined, Date | undefined];

const normalizeDateRange = (anyValue: unknown): NormalizedDateRange => {
  if (
    Array.isArray(anyValue) &&
    anyValue[0] instanceof Date &&
    anyValue[1] instanceof Date
  ) {
    const [fromDate, toDate] = anyValue;
    return [fromDate, toDate];
  }

  if (
    anyValue instanceof Object &&
    'from' in anyValue &&
    'to' in anyValue &&
    anyValue.from instanceof Date &&
    anyValue.to instanceof Date
  ) {
    const { from: fromDate, to: toDate } = anyValue;
    return [fromDate, toDate];
  }

  return [undefined, undefined];
};

const dateRangeIsToday = ([from, to]: NormalizedDateRange): boolean => {
  const today = startOfDay(new Date());
  return (
    (from && to && isSameDay(today, from) && isSameDay(today, to)) || false
  );
};

const dateRangeIsYesterday = ([from, to]: NormalizedDateRange): boolean => {
  const today = startOfDay(addDays(new Date(), -1));
  return (
    (from && to && isSameDay(today, from) && isSameDay(today, to)) || false
  );
};

export const CalendarFormControl = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  props: CalendarFormControlProps<TFieldValues, TName>
) => {
  const { t } = useTranslation();
  const { label, className, placeholder, readOnly, ...others } = props;
  const { name } = others;
  const {
    field: { onChange, value, disabled }
  } = useController(others);

  const [open, setOpen] = useState(false);

  const ref = useRef<HTMLButtonElement>(null);
  const handleClick = useCallback(() => {
    setOpen(orig => !orig);
  }, []);
  const handleClickAway = useCallback(() => {
    setOpen(false);
  }, []);

  const id = open ? 'simple-popper' : undefined;

  const buttonTitle = useMemo(() => {
    const anyValue = value as unknown;
    if (anyValue instanceof Date) {
      return `${formatDate(anyValue, 'dd MMM yyyy')}`;
    }

    const [fromDate, toDate] = normalizeDateRange(anyValue);
    if (fromDate && toDate) {
      if (dateRangeIsToday([fromDate, toDate])) {
        return t('components.forms.CalendarFormControl.today');
      } else if (dateRangeIsYesterday([fromDate, toDate])) {
        return t('components.forms.CalendarFormControl.yesterday');
      } else if (isSameDay(fromDate, toDate)) {
        return formatDate(toDate, 'dd MMM yyyy');
      } else if (isSameDay(startOfMonth(fromDate), startOfMonth(toDate))) {
        return `${formatDate(fromDate, 'dd')}-${formatDate(toDate, 'dd MMM yyyy')}`;
      } else {
        return formatDistance(toDate, fromDate);
      }
    }

    return placeholder;
  }, [placeholder, t, value]);

  const hasValue = useMemo(() => {
    return Boolean(normalizeDateRange(value)[0]);
  }, [value]);

  const calendarProps: CalendarProps = useMemo(() => {
    return {
      ...others,
      onSelect: onChange,
      selected: value
    };
  }, [onChange, others, value]);

  /*
  const handleTodayClick = useCallback(() => {
    const today = startOfDay(new Date());
    onChange({ from: today, to: today });
  }, [onChange]);
  */

  const handleYesterdayClick = useCallback(() => {
    const yesterday = startOfDay(addDays(new Date(), -1));
    onChange({ from: yesterday, to: yesterday });
  }, [onChange]);

  const selectedDay = useMemo(() => {
    const normalized = normalizeDateRange(value);
    if (dateRangeIsToday(normalized)) {
      return 'today';
    } else if (dateRangeIsYesterday(normalized)) {
      return 'yesterday';
    } else {
      return undefined;
    }
  }, [value]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      {/* must use a div here for click-away */}
      <div>
        <FormControl
          className={cn(className, { 'opacity-50': disabled || readOnly })}
        >
          <label htmlFor={name}>{label}</label>
          <Button
            ref={ref}
            onClick={handleClick}
            className={cn(
              'w-full rounded-lg border border-select-foreground p-3.5 text-left bg-input-background',
              { 'font-bold': hasValue }
            )}
            disabled={disabled || readOnly}
          >
            {buttonTitle}
          </Button>
          <Popper id={id} open={open} anchorEl={ref.current}>
            <div className="my-4 divide-y divide-solid rounded-lg border border-select-foreground bg-white text-left">
              {/* Currently disabled because it is confusing to the user.
              <Button
                onClick={handleTodayClick}
                className={cn('w-full p-4 text-left', {
                  'font-bold': selectedDay === 'today'
                })}
              >
                <Trans i18nKey="components.forms.CalendarFormControl.today" />
              </Button>
              */}
              <Button
                onClick={handleYesterdayClick}
                className={cn('w-full p-4 text-left', {
                  'font-bold': selectedDay === 'yesterday'
                })}
              >
                <Trans i18nKey="components.forms.CalendarFormControl.yesterday" />
              </Button>
              <Calendar {...calendarProps} className="p-4 " />
            </div>
          </Popper>
        </FormControl>
      </div>
    </ClickAwayListener>
  );
};
