import { PropsWithChildren } from 'react';
import { Link, Outlet } from 'react-router-dom';

import { Logo } from '../components/Logo';
import { Navigation } from '../components/Navigation';
import { UserMenu } from '../components/UserMenu';

export interface LayoutProps extends PropsWithChildren {}

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div className="flex min-h-screen min-w-[100vw] flex-col">
      <header className="flex h-36 place-items-center bg-background">
        <div className="grow pl-12">
          <Link to="/">
            <Logo />
          </Link>
        </div>
        <div className="pr-5">
          <UserMenu />
        </div>
      </header>
      <div className="flex grow">
        <div className="min-w-64 bg-nav-default">
          <Navigation />
        </div>
        <div className="grow">{children ?? <Outlet />}</div>
      </div>
    </div>
  );
};
