import { FC, useMemo } from 'react';

import { Device, Monitor } from '../../api/types';
import { MonitorOperationalWidget } from '../../components/MonitorOperationalWidget';
import { MonitorTable } from '../../components/MonitorTable';
import { NotifiedDeviceWidget } from '../../components/NotifiedDeviceWidget';
import { RecognitionAccuracyWidget } from '../../components/RecognitionAccuracyWidget';
import { TODO } from '../../components/TODO';
import { monitorIsOnline } from '../../models/Monitor';

interface StatusScreenContentProps {
  monitors: Monitor[];
  devices: Device[];
}

export const StatusScreenContent: FC<StatusScreenContentProps> = ({
  monitors,
  devices
}) => {
  const operationalCount = useMemo(() => {
    return monitors.filter(monitorIsOnline).length;
  }, [monitors]);
  const deviceCount = useMemo(() => {
    return devices.length;
  }, [devices]);
  return (
    <main>
      <aside className="flex gap-x-2 px-4 pt-6">
        <MonitorOperationalWidget count={operationalCount} />
        <NotifiedDeviceWidget count={deviceCount} />
        <TODO>
          <RecognitionAccuracyWidget count={93} />
        </TODO>
      </aside>
      <MonitorTable data={monitors} className="w-full" />
    </main>
  );
};
