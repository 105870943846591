import { FC } from 'react';
import { Trans } from 'react-i18next';

import hero from '../assets/monitor-operational.png';

interface MonitorOperationalWidgetProps {
  count: number;
}

export const MonitorOperationalWidget: FC<MonitorOperationalWidgetProps> = ({
  count
}) => {
  return (
    <div className="inline-block">
      <div
        style={{ backgroundImage: `url("${hero}")` }}
        className="flex h-[71px]  w-[312px] flex-col bg-[length:97px_71px] bg-no-repeat pl-[84px]"
      >
        <div className="truncate text-3xl uppercase">
          <Trans
            i18nKey="components.MonitorOperationalWidget.text"
            values={{ count }}
          >
            <strong>some</strong>monitors
          </Trans>
        </div>
        <div className="text-base uppercase">
          <Trans
            i18nKey="components.MonitorOperationalWidget.subtext"
            values={{ count }}
          />
        </div>
      </div>
    </div>
  );
};
