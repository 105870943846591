import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import {
  useFetchLatestMonitorFirmwareUpdateQuery,
  useFetchMonitorAvailableFirmwaresQuery,
  useUpdateMonitorFirmwareMutation
} from '../api/backendApi';

export const DevelopmentMonitorFirmwareDemoScreen = () => {
  const { monitorId } = useParams();
  const { data: firmwaresData } = useFetchMonitorAvailableFirmwaresQuery({
    monitorId: monitorId!,
    track: 'beta'
  });

  const { data: updateData, refetch: refetchUpdateData } =
    useFetchLatestMonitorFirmwareUpdateQuery({
      monitorId: monitorId!
    });

  const [sendUpdateNow] = useUpdateMonitorFirmwareMutation();

  useEffect(() => {
    const handle = setInterval(refetchUpdateData, 5000);
    return () => {
      clearTimeout(handle);
    };
  }, [refetchUpdateData]);

  const updateNow = useCallback(async () => {
    try {
      await sendUpdateNow({
        monitorId: monitorId!,
        body: { track: 'beta' }
      }).unwrap();
      await refetchUpdateData();
      alert('Update triggered.');
    } catch (err) {
      console.log(err);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      alert((err as any).data?.message);
    }
  }, [monitorId, refetchUpdateData, sendUpdateNow]);

  return (
    <main>
      <div className="my-6">Monitor ID: {String(monitorId)}</div>
      <div className="my-6">
        <div>
          Firmware available: {String(firmwaresData?.update_available)}{' '}
        </div>
        {firmwaresData?.update_available &&
          updateData?.status !== 'queued' &&
          updateData?.status !== 'in-progress' && (
            <>
              <pre>{JSON.stringify(firmwaresData.firmwares[0], null, 2)}</pre>
              <button className="border-2 p-2" onClick={updateNow}>
                Update
              </button>
            </>
          )}
      </div>
      <div className="my-6">
        <div>Latest update job:</div>
        <pre>{JSON.stringify(updateData, null, 2)}</pre>
      </div>
    </main>
  );
};
