import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Bar,
  CartesianGrid,
  Cell,
  ComposedChart,
  Dot,
  Label,
  Legend,
  Line,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';

import { SoundOption } from '../../api/types';
import { getChartNotice } from '../../components/charts/ChartNotice';
import { useDataAggregate } from '../../hooks/charts/useDataAggregate';
import { useColorPreference } from '../../hooks/useColorPreference';
import { useRechartsTimeAxisFormatter } from '../../hooks/useRechartsTimeAxisFormatter';
import { useRechartsTooltipFormatter } from '../../hooks/useRechartsTooltipFormatter';
import { useRechartsYAxisFormatter } from '../../hooks/useRechartsYAxisFormatter';
import { StatisticsQueryDateOptionsGranularity } from '../../models/Granularity';
import { calculateBarOpacity } from '../../utils/charts';

export type HourlyChartDataPoint = {
  hour: string;
} & Record<Exclude<string, 'hour'>, number>;

export interface HourlyChartProps {
  title: string;
  subtitles: string[];
  leftAxisTitle: string;
  rightAxisTitle: string;
  chartType: 'total' | 'anomaly';
  data: HourlyChartDataPoint[];
  granularity?: StatisticsQueryDateOptionsGranularity;
  soundOptions: Record<string, SoundOption>;
  soundSlugs: string[];
}

export const HourlyChart: FC<HourlyChartProps> = ({
  title,
  subtitles,
  data,
  leftAxisTitle,
  rightAxisTitle,
  chartType,
  granularity = '1h' as const,
  soundOptions,
  soundSlugs
}) => {
  const { t } = useTranslation();
  const { getSoundColor } = useColorPreference();
  const width = 1000;
  const height = 800;
  const legendOffset = 80;

  const { average, maximum, nonZeroCount } = useDataAggregate<string>({
    data,
    selectors: soundSlugs
  });

  const hasEnoughDataForAnomalies = useMemo(() => {
    return nonZeroCount !== undefined && nonZeroCount >= 3;
  }, [nonZeroCount]);

  const hasSignificantDataForAnomalies = !maximum || maximum >= 1;

  const effectiveChartType =
    chartType == 'anomaly' &&
    hasEnoughDataForAnomalies &&
    hasSignificantDataForAnomalies
      ? chartType
      : 'total';

  const tooltipFormatter = useRechartsTooltipFormatter();

  const yAxisFormatter = useRechartsYAxisFormatter();

  const xAxisFormatter = useRechartsTimeAxisFormatter({ granularity });

  const barOpacity = useCallback(
    (entry: HourlyChartDataPoint) => {
      if (effectiveChartType !== 'anomaly' || !average || !maximum) {
        return 1;
      }

      return calculateBarOpacity({
        value: soundSlugs.reduce((p, selector) => entry[selector] + p, 0),
        average,
        maximum
      });
    },
    [average, effectiveChartType, maximum, soundSlugs]
  );

  const anomalyNoticeLeft = 80;

  const attendanceDotProps = useMemo(
    () => ({
      fill: '#f2b13d',
      strokeWidth: 1,
      r: 5,
      strokeDasharray: ''
    }),
    []
  );

  const renderAttendanceDot = useCallback(
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    (props: any) => {
      if (props.payload.attendance == 0) {
        return <></>;
      }
      return <Dot {...props} {...attendanceDotProps} />;
    },
    [attendanceDotProps]
  );

  return (
    <ComposedChart
      width={width}
      height={height}
      data={data}
      margin={{
        top: 60 + 10 + subtitles.length * 20,
        right: 20,
        left: 20,
        bottom: legendOffset
      }}
    >
      <text
        x={width / 2}
        y={20}
        fill="black"
        textAnchor="middle"
        dominantBaseline="central"
      >
        <tspan fontSize="24" x={width / 2}>
          {title}
        </tspan>
        {subtitles.map((subtitle, i) => {
          return (
            <tspan
              fontSize="16"
              x={width / 2}
              dy={(i == 0 ? 10 : 0) + 20}
              key={i}
            >
              {subtitle}
            </tspan>
          );
        })}
      </text>
      {effectiveChartType !== chartType && chartType == 'anomaly'
        ? getChartNotice({
            x: anomalyNoticeLeft,
            y: 60,
            label: t('components.charts.ChartNotice.text')
          })
        : null}
      <CartesianGrid vertical={false} />
      <XAxis
        dataKey="hour"
        tickLine={false}
        angle={-90}
        textAnchor="end"
        dx={-5}
        tickFormatter={xAxisFormatter}
      />
      <YAxis
        yAxisId="left"
        axisLine={false}
        tickLine={false}
        domain={
          soundSlugs.length <= 1 &&
          effectiveChartType === 'anomaly' &&
          average &&
          maximum
            ? [average, maximum]
            : undefined
        }
        allowDataOverflow={effectiveChartType === 'anomaly'}
        tickFormatter={yAxisFormatter}
      >
        <Label
          dx={-40}
          value={leftAxisTitle}
          style={{ fill: 'black' }}
          angle={-90}
        />
      </YAxis>
      <YAxis
        yAxisId="right"
        axisLine={false}
        tickLine={false}
        orientation="right"
      >
        <Label
          dx={40}
          value={rightAxisTitle}
          style={{ fill: 'black' }}
          angle={-90}
        />
      </YAxis>
      <Tooltip formatter={tooltipFormatter} />
      <Legend wrapperStyle={{ marginBottom: -legendOffset }} />
      {soundSlugs.map(slug => (
        <Bar
          key={slug}
          yAxisId="left"
          stackId={'one'}
          dataKey={slug}
          barSize={40}
          fill={getSoundColor(slug)}
          name={(soundOptions[slug]?.value ?? 'Unknown').toUpperCase()}
        >
          {effectiveChartType === 'anomaly' && average
            ? data.map((entry, index) => (
                <Cell key={`cell-${index}`} opacity={barOpacity(entry)} />
              ))
            : null}
        </Bar>
      ))}
      <Line
        yAxisId="right"
        type="monotone"
        dot={renderAttendanceDot}
        activeDot={attendanceDotProps}
        dataKey="attendance"
        stroke="#f2b13d"
        strokeDasharray="3 3"
        name={t('DataViewMonitor.HourlyChart.attendance').toUpperCase()}
      />
    </ComposedChart>
  );
};
