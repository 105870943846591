import { useContext } from 'react';

import {
  ColorPreferenceContext,
  ColorPreferenceContextValue
} from '../providers/ColorPreferenceProvider';

export const useColorPreference = (): ColorPreferenceContextValue => {
  return useContext(ColorPreferenceContext);
};
