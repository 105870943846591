import { Path } from 'd3-path';
import { CurveGenerator } from 'd3-shape';
import { PolynomialRegression } from 'ml-regression-polynomial';

interface PolynomialOptions {
  order: number;
  range?: [number, number];
}

// Adapted from https://github.com/d3/d3-shape/blob/main/src/curve/linear.js
class Polynomial implements CurveGenerator {
  _context: CanvasRenderingContext2D | Path;
  _options: PolynomialOptions;
  _line: number = NaN;
  _x: number[];
  _y: number[];

  constructor(
    context: CanvasRenderingContext2D | Path,
    options: PolynomialOptions
  ) {
    this._context = context;
    this._options = options;
    this._x = [];
    this._y = [];
  }

  areaStart() {
    this._line = 0;
  }

  areaEnd() {
    this._line = NaN;
  }

  lineStart() {
    this._x = [];
    this._y = [];
  }

  lineEnd() {
    const len = this._x.length;
    if (this._line || (this._line !== 0 && len === 1)) {
      this._context.closePath();
    }

    this._line = 1 - this._line;

    if (len === 0) {
      return;
    }

    const regression = new PolynomialRegression(
      this._x,
      this._y,
      this._options.order
    );

    let [lower, upper] = this._options.range ?? [undefined, undefined];
    if (lower && upper && lower > upper) {
      [lower, upper] = [upper, lower];
    }

    const bound = (y: number) => {
      if (lower && upper) {
        return Math.min(Math.max(y, lower), upper);
      } else {
        return y;
      }
    };

    const pathTo = (x: number, y: number) => {
      if (lower && upper && (y < lower || y > upper)) {
        this._context.moveTo(x, bound(y));
      } else {
        this._context.lineTo(x, y);
      }
    };

    const domain = [this._x[0], this._x[len - 1]];
    this._context.moveTo(domain[0], bound(regression.predict(domain[0])));
    for (let x = domain[0] + 1; x < domain[1]; x++) {
      pathTo(x, regression.predict(x));
    }
  }
  point(x: number, y: number) {
    (x = +x), (y = +y);

    // We have to get all the points before we can calculate the polynomial
    // regression.
    this._x.push(x);
    this._y.push(y);
  }
}

interface PolynomialBuilder {
  (context: CanvasRenderingContext2D | Path): CurveGenerator;
  order(order: number): PolynomialBuilder;
  range(range: [number, number]): PolynomialBuilder;
}

const builder = (options: PolynomialOptions): PolynomialBuilder => {
  return Object.assign(
    (context: CanvasRenderingContext2D | Path) => {
      return new Polynomial(context, options);
    },
    {
      order: (order: number) => {
        return builder({ ...options, order });
      },
      range: (range: [number, number]) => {
        return builder({ ...options, range });
      }
    }
  );
};

export const polynomial = builder({ order: 4 });
