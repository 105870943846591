import { Trans } from 'react-i18next';

import earzzLogo from '../assets/earzz-logo.svg';

export const Logo = () => {
  return (
    <div className="w-[414px]">
      <img src={earzzLogo} className="h-[60px]" alt="Earzz" />
      <span className="block pr-[14px] text-right text-xl font-bold uppercase text-black">
        <Trans i18nKey="components.logo.dashboard" />
      </span>
    </div>
  );
};
