import randomColor from 'randomcolor';
import React, { PropsWithChildren, ReactElement, useCallback } from 'react';

export type ColorPreferenceContextValue = {
  getSoundColor: (slug: string) => string;
};

export const ColorPreferenceContext =
  React.createContext<ColorPreferenceContextValue>(undefined!);

interface ColorPreferenceProviderProps extends PropsWithChildren {}

const ColorPreferencePreferredColor: Record<string, string> = Object.freeze({
  // NOTE: Currently disabled as these colors are pending review.
  //snore: '#ffe0ae',
  //'water-running': '#25c0c0',
  //scream: '#f54d3d',
  //clap: '#fd8b15',
  //thud: '#9ab198',
  //gasp: '#9ea7ca',
  //footstep: '#3d5b59'
});

export const ColorPreferenceProvider = ({
  children
}: ColorPreferenceProviderProps): ReactElement => {
  const getSoundColor = useCallback((slug: string): string => {
    return ColorPreferencePreferredColor[slug] ?? randomColor({ seed: slug });
  }, []);
  const value = {
    getSoundColor
  };
  return (
    <ColorPreferenceContext.Provider value={value}>
      {children}
    </ColorPreferenceContext.Provider>
  );
};
