import { Popper } from '@mui/base/Popper';
import { FC, useCallback, useRef, useState } from 'react';

import { Device } from '../api/types';

interface MonitorTableDevicesCellProps {
  devices: Device[];
}

export const MonitorTableDevicesCell: FC<MonitorTableDevicesCellProps> = ({
  devices
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [shown, setShown] = useState(false);
  const handleEnter = useCallback(() => {
    setShown(true);
  }, []);
  const handleLeave = useCallback(() => {
    setShown(false);
  }, []);

  return (
    <>
      <span
        onMouseEnter={handleEnter}
        onMouseLeave={handleLeave}
        ref={ref}
        className="p-2"
      >
        {devices.length}
      </span>
      <Popper
        open={shown}
        anchorEl={ref.current}
        className="max-w-72 border-2 border-black bg-table-row px-2 py-1"
      >
        {devices.map(device => (
          <div key={device.id}>{device.name}</div>
        ))}
      </Popper>
    </>
  );
};
