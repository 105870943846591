import { FC, PropsWithChildren, useLayoutEffect, useRef } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { useFetchStatisticsSoundOptionsQuery } from '../api/backendApi';
import { received as soundOptionsReceived } from '../store/soundOptions';

interface PreloadProviderProps extends PropsWithChildren {}

export const PreloadProvider: FC<PreloadProviderProps> = ({ children }) => {
  const dispatch = useDispatch();
  const runOnceRef = useRef(false);
  const { data: soundOptions, isLoading: isLoadingSoundOptions } =
    useFetchStatisticsSoundOptionsQuery(
      {
        includesExtras: true
      },
      {
        selectFromResult: data => {
          return { ...data, data: data?.data?.sound_options };
        }
      }
    );

  useLayoutEffect(() => {
    if (soundOptions) {
      dispatch(soundOptionsReceived(soundOptions));
    }
    if (!isLoadingSoundOptions) {
      runOnceRef.current = true;
    }
  }, [dispatch, isLoadingSoundOptions, soundOptions]);

  const isLoading = isLoadingSoundOptions || !runOnceRef.current;

  if (isLoading) {
    return <Trans i18nKey="loading" />;
  }

  return children;
};
