import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useIntl } from 'react-intl';
import {
  Bar,
  CartesianGrid,
  Cell,
  ComposedChart,
  Label,
  LabelList,
  ReferenceLine,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';

import { getChartNotice } from '../../components/charts/ChartNotice';
import { useDataAggregate } from '../../hooks/charts/useDataAggregate';
import { useRechartsCategoryAxisFormatter } from '../../hooks/useRechartsCategoryAxisFormatter';
import { useRechartsTooltipFormatter } from '../../hooks/useRechartsTooltipFormatter';
import { calculateBarOpacity } from '../../utils/charts';
import { AdaptChildMouseEventHandler } from '../../utils/recharts/types';

export interface PerMonitorChartDataItem {
  monitor_nickname: string;
  monitor_id: string;
  events: number;
  monitor_status?: 'online' | 'offline';
}

export interface PerMonitorChartProps {
  data: PerMonitorChartDataItem[];
  title: string;
  subtitles: string[];
  yAxisTitle: string;
  barColor?: string;
  chartType: 'total' | 'anomaly';
  onSelect?: (item: PerMonitorChartDataItem) => void;
}

export const PerMonitorChart: FC<PerMonitorChartProps> = ({
  title,
  subtitles,
  data,
  yAxisTitle,
  barColor = '#f9db56',
  chartType,
  onSelect
}) => {
  const { t } = useTranslation();
  const intl = useIntl();
  const width = 1000;
  const height = 800;

  const tooltipFormatter = useRechartsTooltipFormatter();

  const { average, maximum } = useDataAggregate({ data, selector: 'events' });

  const hasEnoughDataForAnomalies = useMemo(() => {
    if (data.length == 0) return false;

    const total = data.filter(d => d.events > 0).length;
    return total >= 3;
  }, [data]);

  const hasSignificantDataForAnomalies = !maximum || maximum >= 1;

  const effectiveChartType =
    chartType == 'anomaly' &&
    hasEnoughDataForAnomalies &&
    hasSignificantDataForAnomalies
      ? chartType
      : 'total';

  const barOpacity = useCallback(
    (entry: (typeof data)[0]) => {
      if (effectiveChartType !== 'anomaly' || !average || !maximum) {
        return 1;
      }

      return calculateBarOpacity({
        value: entry.events,
        average,
        maximum
      });
    },
    [average, effectiveChartType, maximum]
  );

  const monitorFormatter = useRechartsCategoryAxisFormatter({
    labelMaxLength: 18
  });

  const handleMonitorBarClick = useCallback<
    AdaptChildMouseEventHandler<PerMonitorChartDataItem, SVGElement>
  >(
    data => {
      onSelect?.(data);
    },
    [onSelect]
  );

  const monitorStatusFormatter = useCallback(
    (value: unknown) => {
      if (value === 'offline') {
        return t('DataViewSound.PerMonitorChart.xAxis.monitorStatus.offline');
      }
      return '';
    },
    [t]
  );

  const chartMarginTop = 60 + subtitles.length * 20;
  const anomalyNoticeLeft = 80;

  return (
    <ComposedChart
      width={width}
      height={height}
      data={data}
      margin={{
        top: chartMarginTop + 10,
        right: 0,
        left: 20,
        bottom: 140
      }}
    >
      <text
        x={width / 2}
        y={20}
        fill="black"
        textAnchor="middle"
        dominantBaseline="central"
      >
        <tspan fontSize="24" x={width / 2}>
          {title}
        </tspan>
        {subtitles.map((subtitle, i) => {
          return (
            <tspan
              fontSize="16"
              x={width / 2}
              dy={(i == 0 ? 10 : 0) + 20}
              key={i}
            >
              {subtitle}
            </tspan>
          );
        })}
      </text>
      {effectiveChartType !== chartType && chartType == 'anomaly'
        ? getChartNotice({
            x: anomalyNoticeLeft,
            y: 60,
            label: t('components.charts.ChartNotice.text')
          })
        : null}
      <CartesianGrid vertical={false} />
      <XAxis
        dataKey="monitor_nickname"
        tickLine={false}
        angle={-90}
        textAnchor="end"
        tickFormatter={monitorFormatter}
        dx={-5}
      />
      <YAxis axisLine={false} tickLine={false}>
        <Label
          dx={-40}
          value={yAxisTitle}
          position="center"
          style={{ fill: 'black' }}
          angle={-90}
        />
      </YAxis>
      <Tooltip formatter={tooltipFormatter} />
      <Bar
        dataKey="events"
        barSize={40}
        fill={barColor}
        cursor="pointer"
        onClick={handleMonitorBarClick}
      >
        {effectiveChartType === 'anomaly' && average
          ? data.map((entry, index) => (
              <Cell key={`cell-${index}`} opacity={barOpacity(entry)} />
            ))
          : null}
        <LabelList
          dataKey="monitor_status"
          position="insideBottom"
          angle={-90}
          width={300}
          dx={5}
          dy={0}
          textAnchor="end"
          style={{ textAnchor: 'start' }}
          formatter={monitorStatusFormatter}
          opacity={0.5}
        />
      </Bar>
      {effectiveChartType === 'anomaly' && average && (
        <ReferenceLine y={average} stroke="#f2b13d" strokeWidth={2}>
          <Label
            position="insideTopLeft"
            value={t('DataViewSound.PerMonitorChart.mean.label', {
              value: intl.formatNumber(average, { maximumSignificantDigits: 3 })
            })}
          ></Label>
        </ReferenceLine>
      )}
    </ComposedChart>
  );
};
