import { addDays, differenceInDays, isPast, isSameDay } from 'date-fns';
import { useCallback, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ResponsiveContainer } from 'recharts';
import { StringParam, useQueryParams } from 'use-query-params';

import {
  useFetchMonitorsQuery,
  useFetchStatisticsMonitorOptionsQuery
} from '../../api/backendApi';
import { ChartDateSelector } from '../../components/ChartDateSelector';
import { ChartIntervalSelector } from '../../components/ChartIntervalSelector';
import { ChartLoadingContainer } from '../../components/ChartLoadingContainer';
import { Curtain } from '../../components/Curtain';
import { KeyRequirementWidget } from '../../components/KeyRequirementWidget';
import { StatisticsQueryDateOptionsGranularity } from '../../models/Granularity';
import { temporaryIdentifyKeyRequirement } from '../../utils/key-requirements';
import { isNonNull } from '../../utils/null';
import {
  CommaDelimitedArrayParam,
  CommaDelimitedDateRangeParam,
  CommaDelimitedTimeRangeParam,
  EnumParam
} from '../../utils/queryparams';
import { DailyChart } from './DailyChart';
import {
  MonitorFilterForm,
  MonitorFilterFormInputs,
  MonitorFilterFormInputsSchema
} from './FilterForm';
import { HourlyChart } from './HourlyChart';
import { useFetchDailyChartProps } from './useFetchDailyChartProps';
import { useFetchHourlyChartProps } from './useFetchHourlyChartProps';

export const DataViewMonitorScreen = () => {
  const { t } = useTranslation();
  const [queryParams, setQueryParams] = useQueryParams({
    monitor_id: StringParam,
    sound_slugs: CommaDelimitedArrayParam,
    date_range: CommaDelimitedDateRangeParam,
    time_range: CommaDelimitedTimeRangeParam,
    graph: EnumParam<'count' | 'average'>(['count', 'average'])
  });

  const allowedGranularities: StatisticsQueryDateOptionsGranularity[] =
    useMemo(() => {
      const { from: fromDate } = queryParams.date_range ?? {};
      if (fromDate && isPast(addDays(fromDate, 11))) {
        return ['1h'];
      } else {
        return ['5m', '1h'];
      }
    }, [queryParams.date_range]);

  const [granularity, setGranularity] =
    useState<StatisticsQueryDateOptionsGranularity>('1h');

  const effectiveGranularity = allowedGranularities.includes(granularity)
    ? granularity
    : '1h';

  const handleChangeGranularity = useCallback(
    (value: StatisticsQueryDateOptionsGranularity) => {
      if (value === '5m') {
        if (!allowedGranularities.includes(value)) {
          const willChange = confirm(
            t('alert.intervalWillChangeDateRange', {
              interval: '5 minutes',
              date_range: 'last 10 days'
            })
          );
          if (!willChange) {
            return;
          }

          setQueryParams(existingValues => {
            return {
              ...existingValues,
              date_range: {
                from: addDays(new Date(), -10),
                to: addDays(new Date(), -1)
              }
            };
          });
        }
      }

      setGranularity(value);
    },
    [allowedGranularities, setQueryParams, t]
  );

  const [dailyChartDateSelectorDate, setDailyChartDateSelectorDate] =
    useState<Date>();

  const handleFilterChange = useCallback(
    (values: MonitorFilterFormInputs | null) => {
      setQueryParams(
        values ?? {
          monitor_id: undefined,
          sound_slugs: undefined,
          date_range: undefined,
          time_range: undefined,
          graph: undefined
        }
      );

      const { from, to } = values?.date_range ?? {};
      if (dailyChartDateSelectorDate) {
        if (
          !from ||
          from > dailyChartDateSelectorDate ||
          !to ||
          to < dailyChartDateSelectorDate
        ) {
          setDailyChartDateSelectorDate(undefined);
        }
      }
    },
    [dailyChartDateSelectorDate, setQueryParams]
  );

  const formValues = useMemo(() => {
    const { monitor_id, date_range, time_range, graph, sound_slugs } =
      queryParams;
    return {
      monitor_id: monitor_id ?? undefined,
      date_range: date_range ?? undefined,
      time_range: time_range ?? undefined,
      graph: graph ?? undefined,
      sound_slugs: sound_slugs ?? undefined
    };
  }, [queryParams]);

  const { data: selectedMonitorName } = useFetchStatisticsMonitorOptionsQuery(
    {},
    {
      selectFromResult: result => {
        const selectedMonitorId = queryParams.monitor_id;
        return {
          ...result,
          data:
            result.data?.monitor_options?.filter(
              o => o.key == selectedMonitorId
            )?.[0]?.value ?? 'Unknown'
        };
      }
    }
  );

  const { data: selectedMonitor } = useFetchMonitorsQuery(
    {},
    {
      selectFromResult: result => {
        const selectedMonitorId = queryParams.monitor_id;
        const { data } = result;
        const monitor = data?.find(m => String(m.id) === selectedMonitorId);
        return {
          ...result,
          data: monitor
        };
      }
    }
  );

  const selectedMonitorKeyRequirement = useMemo(() => {
    if (!selectedMonitor) {
      return undefined;
    }

    return temporaryIdentifyKeyRequirement(
      String(selectedMonitor.id),
      selectedMonitor.sounds?.map(sound => sound.slug).filter(isNonNull) ?? []
    );
  }, [selectedMonitor]);

  const displayedGranularities: StatisticsQueryDateOptionsGranularity[] =
    useMemo(() => {
      return ['5m', '1h'];
    }, []);

  const params = useMemo(() => {
    return MonitorFilterFormInputsSchema.safeParse(queryParams).data ?? null;
  }, [queryParams]);

  const { isFetching: chartDataIsFetching, chartProps: hourlyChartProps } =
    useFetchHourlyChartProps({
      params,
      granularity: effectiveGranularity,
      selectedMonitorName,
      dateSelectorDate: dailyChartDateSelectorDate
    });

  const { isFetching: dailyEventDataIsFetching, chartProps: dailyChartProps } =
    useFetchDailyChartProps({
      params,
      selectedMonitorName
    });

  const dailyChartDateSelectorRange = useMemo(() => {
    const { from, to } = queryParams.date_range ?? {};
    if (!from || !to) {
      return null;
    }

    if (isSameDay(from, to)) {
      return null;
    }

    if (differenceInDays(to, from) > 14) {
      return null;
    }

    return { from, to };
  }, [queryParams.date_range]);

  return (
    <main className="mb-12 flex flex-col gap-y-6 p-9">
      <header>
        <h2 className="font-bold uppercase">
          <Trans i18nKey="DataViewMonitor.title" />
        </h2>
        <p>
          <Trans i18nKey="DataViewMonitor.subtitle" />
        </p>
      </header>
      <MonitorFilterForm values={formValues} onChange={handleFilterChange} />
      {params ? (
        <div>
          {selectedMonitorKeyRequirement && (
            <KeyRequirementWidget requirement={selectedMonitorKeyRequirement} />
          )}
          <div className="mt-4 flex gap-8">
            <ChartIntervalSelector
              value={effectiveGranularity}
              onChange={handleChangeGranularity}
              choices={displayedGranularities}
            />
            {dailyChartDateSelectorRange ? (
              <ChartDateSelector
                range={dailyChartDateSelectorRange}
                value={dailyChartDateSelectorDate}
                onChange={setDailyChartDateSelectorDate}
              />
            ) : (
              <></>
            )}
          </div>
          <ChartLoadingContainer isLoading={chartDataIsFetching}>
            <ResponsiveContainer width="100%" height={800}>
              {hourlyChartProps ? <HourlyChart {...hourlyChartProps} /> : <></>}
            </ResponsiveContainer>
          </ChartLoadingContainer>
          <ChartLoadingContainer isLoading={dailyEventDataIsFetching}>
            <ResponsiveContainer
              width="100%"
              height={800}
              className="mt-12 border-t-2 pt-12"
            >
              {dailyChartProps ? <DailyChart {...dailyChartProps} /> : <></>}
            </ResponsiveContainer>
          </ChartLoadingContainer>
        </div>
      ) : (
        <Curtain act="no-selection" className="min-h-96" />
      )}
    </main>
  );
};
