import type {
  DefinitionsFromApi,
  OverrideResultType,
  TagTypesFromApi
} from '@reduxjs/toolkit/query';

import {
  backendApi,
  FetchStatisticsMonitorOptionsApiResponse,
  Monitor as BaseMonitor
} from './generated/backendApi';
import { Monitor } from './types';

type Definitions = DefinitionsFromApi<typeof backendApi>;
type TagTypes = TagTypesFromApi<typeof backendApi>;

type UpdatedDefinitions = Definitions & {
  // Only when overriding result type:
  //login: OverrideResultType<Definitions['login'], void>;
  fetchMonitors: OverrideResultType<Definitions['fetchMonitors'], Monitor[]>;
  fetchStatisticsMonitorOptions: OverrideResultType<
    Definitions['fetchStatisticsMonitorOptions'],
    FetchStatisticsMonitorOptionsApiResponse
  >;
};

const enhancedApi = backendApi.enhanceEndpoints<TagTypes, UpdatedDefinitions>({
  endpoints: {
    fetchMonitors: {
      transformResponse: (data: BaseMonitor[]) => {
        return data.map(monitor => {
          return { ...monitor, devices: [] };
        });
      }
    },
    fetchStatisticsMonitorOptions: {
      transformResponse: (data: FetchStatisticsMonitorOptionsApiResponse) => {
        return {
          ...data,
          monitor_options: data.monitor_options.sort((a, b) =>
            a.value.localeCompare(b.value, undefined, { numeric: true })
          )
        };
      }
    }
  }
});

export const {
  useLoginMutation,
  useFetchMonitorsQuery,
  useFetchUserDevicesQuery,
  useFetchStatisticsSoundOptionsQuery,
  useFetchStatisticsMonitorOptionsQuery,
  useFetchStatisticsDateRangeOptionsQuery,
  useFetchStatisticsRoutineOptionsQuery,
  useFetchStatisticsQuery,
  useFetchMonitorAvailableFirmwaresQuery,
  useFetchLatestMonitorFirmwareUpdateQuery,
  useUpdateMonitorFirmwareMutation
} = enhancedApi;

export { enhancedApi as backendApi };
