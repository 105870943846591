import './i18n';

import { useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { Provider as ReduxProvider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import { ColorPreferenceProvider } from './providers/ColorPreferenceProvider';
import { PreloadProvider } from './providers/PreloadProvider';
import { router } from './router';
import { persistor, store } from './store';
import {
  initializeCookieHub,
  initializeGoogleTagManager
} from './utils/tracking';

function App() {
  useEffect(() => {
    initializeCookieHub();
    initializeGoogleTagManager();
  });

  return (
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <IntlProvider locale="en" defaultLocale="en">
          <PreloadProvider>
            <ColorPreferenceProvider>
              <RouterProvider router={router} />
            </ColorPreferenceProvider>
          </PreloadProvider>
        </IntlProvider>
      </PersistGate>
    </ReduxProvider>
  );
}

/* eslint-disable-next-line no-restricted-exports */
export default App;
