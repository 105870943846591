import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Label,
  Legend,
  Line,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';

import { SoundOption } from '../../api/types';
import { getChartNotice } from '../../components/charts/ChartNotice';
import { useColorPreference } from '../../hooks/useColorPreference';
import { useRechartsTooltipFormatter } from '../../hooks/useRechartsTooltipFormatter';
import { polynomial } from '../../utils/recharts/polynomial';

export type DailyChartDataPoint = {
  date_time_date: string;
} & Record<Exclude<string, 'hour'>, number>;

export interface DailyChartProps {
  title: string;
  subtitles: string[];
  leftAxisTitle: string;
  chartType: 'total' | 'anomaly';
  data: DailyChartDataPoint[];
  soundOptions: Record<string, SoundOption>;
  soundSlugs: string[];
}

export const DailyChart: FC<DailyChartProps> = ({
  title,
  subtitles,
  data,
  leftAxisTitle,
  chartType,
  soundOptions,
  soundSlugs
}) => {
  const { t } = useTranslation();
  const { getSoundColor } = useColorPreference();
  const width = 1000;
  const height = 800;
  const legendOffset = 80;

  const hasEnoughDataForAnomalies = data.length >= 7;

  const effectiveChartType =
    chartType == 'anomaly' && hasEnoughDataForAnomalies ? chartType : 'total';

  const tooltipFormatter = useRechartsTooltipFormatter();

  const anomalyNoticeLeft = 80;

  return (
    <ComposedChart
      width={width}
      height={height}
      data={data}
      margin={{
        top: 60 + 10 + subtitles.length * 20,
        right: 0,
        left: 20,
        bottom: legendOffset
      }}
    >
      <text
        x={width / 2}
        y={20}
        fill="black"
        textAnchor="middle"
        dominantBaseline="central"
      >
        <tspan fontSize="24" x={width / 2}>
          {title}
        </tspan>
        {subtitles.map((subtitle, i) => {
          return (
            <tspan
              fontSize="16"
              x={width / 2}
              dy={(i == 0 ? 10 : 0) + 20}
              key={i}
            >
              {subtitle}
            </tspan>
          );
        })}
      </text>
      {chartType == 'anomaly' && !hasEnoughDataForAnomalies
        ? getChartNotice({
            x: anomalyNoticeLeft,
            y: 60,
            label: t('components.charts.ChartNotice.text.trends')
          })
        : null}
      <CartesianGrid vertical={false} />
      <XAxis
        dataKey="date_time_date"
        tickLine={false}
        angle={-90}
        textAnchor="end"
        dx={-5}
      />
      <YAxis axisLine={false} tickLine={false}>
        <Label
          dx={-40}
          value={leftAxisTitle}
          style={{ fill: 'black' }}
          angle={-90}
        />
      </YAxis>
      <Tooltip formatter={tooltipFormatter} />
      <Legend wrapperStyle={{ marginBottom: -legendOffset }} />
      {soundSlugs.map(slug => (
        <Bar
          key={slug}
          stackId={'one'}
          dataKey={slug}
          barSize={40}
          fill={getSoundColor(slug)}
          name={(soundOptions[slug]?.value ?? 'Unknown').toUpperCase()}
        />
      ))}
      {effectiveChartType === 'anomaly' && (
        <Line
          type={polynomial.range([60, 665])}
          dataKey="events"
          stroke="#f2b13d"
          isAnimationActive={false}
          dot={false}
          tooltipType="none"
          strokeWidth={2}
          legendType="none"
        />
      )}
    </ComposedChart>
  );
};
