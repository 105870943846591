import {
  createEntityAdapter,
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit';

import { SoundOption } from '../api/types';

export const soundOptionsAdapter = createEntityAdapter({
  selectId: (sound: SoundOption) => sound.key,
  sortComparer: (a, b) => a.value.localeCompare(b.value)
});

export const soundOptionsSlice = createSlice({
  name: 'soundOptions',
  initialState: soundOptionsAdapter.getInitialState(),
  reducers: {
    received: (state, action: PayloadAction<SoundOption[]>) => {
      soundOptionsAdapter.setAll(state, action.payload);
    }
  }
});

export const { received } = soundOptionsSlice.actions;

export default soundOptionsSlice.reducer;
