import type { OptionProps as BaseOptionProps } from '@mui/base/Option';
import { Option as BaseOption } from '@mui/base/Option';

import { cn } from '../../lib/utils';

export const OptionSymbol: unique symbol = Symbol();

interface OptionProps<OptionValue extends NonNullable<unknown>>
  extends BaseOptionProps<OptionValue> {}

export const Option = <OptionValue extends NonNullable<unknown>>(
  props: OptionProps<OptionValue>
) => {
  const { ...others } = props;
  return (
    <BaseOption
      {...others}
      slotProps={{
        root: state => ({
          className: cn('cursor-pointer px-4 min-w-40', {
            'font-bold': state.selected
          })
        })
      }}
    />
  );
};

Option[OptionSymbol] = OptionSymbol;

export type { OptionProps };
