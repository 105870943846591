import { FC, useMemo } from 'react';
import { Trans } from 'react-i18next';

import { Monitor } from '../api/types';
import { cn } from '../lib/utils';
import { monitorIsOnline } from '../models/Monitor';

export interface MonitorStatusIndicatorProps {
  monitor: Pick<Monitor, 'online' | 'charging'>;
}

export const MonitorStatusIndicator: FC<MonitorStatusIndicatorProps> = ({
  monitor
}) => {
  const online = monitorIsOnline(monitor);
  const { charging } = monitor;
  const i18nKey = useMemo(() => {
    if (online && charging) {
      return 'components.MonitorStatusIndicator.online';
    } else if (online) {
      return 'components.MonitorStatusIndicator.on-battery';
    } else {
      return 'components.MonitorStatusIndicator.offline';
    }
  }, [charging, online]);

  return (
    <div
      className={cn(
        'inline-block rounded-3xl px-2.5 py-0.5 text-sm text-monitor-foreground',
        {
          'bg-monitor-online': online && charging,
          'bg-monitor-battery': online && !charging,
          'bg-monitor-offline': !online
        }
      )}
    >
      <Trans i18nKey={i18nKey} />
    </div>
  );
};
