import { addHours, format as formatDate } from 'date-fns';
import { useCallback } from 'react';
import { BaseAxisProps } from 'recharts/types/util/types';

import { StatisticsQueryDateOptionsGranularity } from '../models/Granularity';
import { parseTimeString } from '../utils/time';

type Formatter = Exclude<BaseAxisProps['tickFormatter'], undefined>;

interface UseRechartsTimeAxisFormatterArgs {
  granularity: StatisticsQueryDateOptionsGranularity;
}

export const useRechartsTimeAxisFormatter = ({
  granularity
}: UseRechartsTimeAxisFormatterArgs): Formatter => {
  const formatter = useCallback(
    (value: unknown) => {
      if (typeof value !== 'string') {
        return String(value);
      }

      try {
        const d = parseTimeString(value);
        if (granularity === '1h') {
          const nextHour = addHours(d, 1);
          return `${formatDate(d, 'HH:mm')}-${formatDate(nextHour, 'HH:mm')}`;
        } else if (granularity === '5m') {
          return `${formatDate(d, 'HH:mm')}`;
        }
      } catch {
        console.warn(`Value "${value}" cannot be formatted.`);
        return String(value);
      }
      return String(value);
    },
    [granularity]
  );
  return formatter;
};
