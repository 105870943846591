import { addDays, formatDate, isSameDay } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';

import { useFetchStatisticsQuery } from '../../api/backendApi';
import { FetchStatisticsApiArg } from '../../api/generated/backendApi';
import { useAppSelector } from '../../hooks/redux';
import { soundOptionsSelectors } from '../../store';
import { pivot } from '../../utils/charts';
import { DailyChartProps } from './DailyChart';
import { MonitorFilterFormInputsSchema } from './FilterForm';
import { makeDailyChartDataPoint } from './utils';

interface UseFetchDailyChartPropsArgs {
  params: z.infer<typeof MonitorFilterFormInputsSchema> | null;
  selectedMonitorName: string;
}

interface UseFetchDailyChartPropsValues {
  chartProps: DailyChartProps | undefined;
  isFetching: boolean;
}

export const useFetchDailyChartProps = ({
  selectedMonitorName,
  params
}: UseFetchDailyChartPropsArgs): UseFetchDailyChartPropsValues => {
  const { t } = useTranslation();
  const [chartProps, setChartProps] = useState<DailyChartProps>();

  const allSoundOptions = useAppSelector(soundOptionsSelectors.selectEntities);

  const selectedSoundName: string =
    (params?.sound_slugs.length ?? 0) > 1
      ? t('DataViewMonitor.DailyChart.chosenSoundsOfConcern')
      : allSoundOptions[params?.sound_slugs[0] ?? '']?.value ?? 'Unknown';

  const date_options = useMemo(() => {
    return {
      time_zone: 'Europe/London',
      start_of_day: params?.time_range?.from ?? '00:00',
      granularity: '1d' as const
    };
  }, [params?.time_range?.from]);

  const filters = useMemo(() => {
    const filters: FetchStatisticsApiArg['body']['filters'] = [];

    filters.push({
      field: 'monitor.id',
      op: '=',
      value: params?.monitor_id
    });

    const { from: start_time, to: end_time } = params?.time_range ?? {};
    if (!start_time || !end_time) {
      return filters;
    }

    const range = [start_time, end_time].map(t => Number(t.split(':')[0]));

    if (range[0] === range[1]) {
      return filters;
    }

    filters.push({
      field: 'date_time.hour',
      op: 'in_hour_range',
      value: range
    });

    return filters;
  }, [params]);

  const date_range = useMemo(() => {
    return params
      ? {
          from: formatDate(params.date_range.from, 'yyyy-MM-dd'),
          to: formatDate(addDays(params.date_range.to, 1), 'yyyy-MM-dd')
        }
      : null;
  }, [params]);

  const eventQuery: FetchStatisticsApiArg['body'] | null = useMemo(() => {
    if (!params || !date_options || !date_range) {
      return null;
    }

    const { graph } = params;

    return {
      date_range,
      date_options,
      filters: [
        ...filters,
        {
          field: 'sound_slug',
          op: 'in',
          value: params.sound_slugs
        }
      ],
      measures: [
        graph == 'count'
          ? { events: 'events.count' }
          : { events: 'events.average' }
      ],
      dimensions: ['date_time.date', { sound_slug: 'sound.slug' }]
    };
  }, [params, date_options, date_range, filters]);

  const { data: eventData, isFetching: eventDataIsFetching } =
    useFetchStatisticsQuery(
      {
        body: eventQuery!
      },
      {
        skip: !eventQuery
      }
    );

  const chartDataIsFetching = eventDataIsFetching;

  const leftAxisTitle = t(
    params?.graph === 'count'
      ? 'DataViewMonitor.HourlyChart.eventsAxis.count'
      : 'DataViewMonitor.HourlyChart.eventsAxis.average',
    {
      sound: selectedSoundName,
      monitor: selectedMonitorName
    }
  );

  const dateRangeTitle = useMemo(() => {
    if (!params?.date_range) {
      return '';
    }

    const { from, to } = params.date_range;
    if (!from || !to) {
      return '';
    }

    if (isSameDay(from, to)) {
      return formatDate(from, 'dd MMM yyyy');
    }

    return `${formatDate(from, 'dd MMM yyyy')} - ${formatDate(to, 'dd MMM yyyy')}`;
  }, [params?.date_range]);

  const timeRangeTitle = useMemo(() => {
    if (!params?.time_range) {
      return '';
    }

    const { from, to } = params?.time_range ?? {};
    if (!from || !to) {
      return '';
    }

    return `${from} - ${to}`;
  }, [params?.time_range]);

  const subtitles = useMemo(
    () => [dateRangeTitle, timeRangeTitle],
    [dateRangeTitle, timeRangeTitle]
  );

  useEffect(() => {
    if (!params || eventDataIsFetching) {
      return;
    }

    setChartProps({
      title: t('DataViewMonitor.DailyChart.title', {
        monitor: selectedMonitorName,
        sound: selectedSoundName
      }),
      subtitles: subtitles,
      data:
        pivot(
          eventData?.data ?? [],
          'date_time_date',
          'sound_slug',
          'events'
        ).map(({ date_time_date, ...others }) => {
          return makeDailyChartDataPoint(
            others as unknown as Record<string, number>, // FIXME,
            {
              date_time_date: formatDate(new Date(date_time_date), 'dd-MM-yyyy')
            }
          );
        }) ?? [],
      leftAxisTitle: leftAxisTitle,
      chartType: params?.graph === 'average' ? 'anomaly' : 'total',
      soundOptions: allSoundOptions,
      soundSlugs: params.sound_slugs
    });
  }, [
    allSoundOptions,
    eventData?.data,
    eventDataIsFetching,
    leftAxisTitle,
    params,
    params?.graph,
    selectedMonitorName,
    selectedSoundName,
    subtitles,
    t
  ]);

  return { chartProps, isFetching: chartDataIsFetching };
};
