import {
  DateParam,
  decodeDelimitedArray,
  encodeDelimitedArray,
  QueryParamConfig,
  StringParam
} from 'use-query-params';

export const CommaDelimitedArrayParam: QueryParamConfig<
  (string | null)[] | null | undefined,
  (string | null)[] | null | undefined
> = {
  encode: obj => {
    return encodeDelimitedArray(obj, ',');
  },

  decode: str => {
    return decodeDelimitedArray(str, ',');
  }
};

export const CommaDelimitedDateRangeParam: QueryParamConfig<
  | { from: Date | null | undefined; to: Date | null | undefined }
  | null
  | undefined
> = {
  encode: obj => {
    if (!obj) return obj;
    const dateStringArray = [obj.from, obj.to]?.map(v => {
      const date = DateParam.encode(v);
      if (!date) {
        return date ?? null;
      }
      return Array.isArray(date) ? date[0] ?? null : date;
    });
    return encodeDelimitedArray(dateStringArray, ',');
  },

  decode: str => {
    const result = decodeDelimitedArray(str, ',')?.map(v =>
      DateParam.decode(v)
    );

    if (result == null || result == undefined) {
      return result;
    }
    return result.length >= 2
      ? { from: result[0] ?? null, to: result[1] ?? null }
      : null;
  }
};

export const CommaDelimitedTimeRangeParam: QueryParamConfig<
  | { from: string | null | undefined; to: string | null | undefined }
  | null
  | undefined
> = {
  encode: obj => {
    if (!obj) return obj;
    const dateStringArray = [obj.from, obj.to]?.map(v => {
      const date = StringParam.encode(v);
      if (!date) {
        return date ?? null;
      }
      return Array.isArray(date) ? date[0] ?? null : date;
    });
    return encodeDelimitedArray(dateStringArray, ',');
  },

  decode: str => {
    const result = decodeDelimitedArray(str, ',')?.map(v =>
      StringParam.decode(v)
    );

    if (result == null || result == undefined) {
      return result;
    }
    return result.length >= 2
      ? { from: result[0] ?? null, to: result[1] ?? null }
      : null;
  }
};

export const EnumParam = <T extends string>(
  values: T[]
): QueryParamConfig<T | null | undefined> => ({
  encode: str => str,

  decode: value => {
    const unwrap = Array.isArray(value) ? value[0] : value;
    if (unwrap === undefined || unwrap === null) return unwrap;
    return values.includes(unwrap as T) ? (value as T) : undefined;
  }
});
